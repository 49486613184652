import {
  AsyncStorage,
  PersistedQuery,
} from '@tanstack/react-query-persist-client';
import DataLoader from 'dataloader';
import { delMany, getMany, set } from 'idb-keyval';

function createAsyncStorage(): AsyncStorage<PersistedQuery> {
  // Create a DataLoader instance to handle batched data loading from idb-keyval
  const getDataLoader = new DataLoader<IDBValidKey, PersistedQuery>(
    (keys: IDBValidKey[]) => getMany(keys),
    {
      cache: false,
    },
  );

  // Create a DataLoader instance to handle batched data deletion from idb-keyval
  const delDataLoader = new DataLoader<IDBValidKey, void>(
    (keys: IDBValidKey[]) =>
      delMany(keys) as unknown as PromiseLike<ArrayLike<void | Error>>,
    {
      cache: false,
    },
  );

  // Define the storage implementation using idb-keyval
  return {
    getItem: async (key) => await getDataLoader.load(key),
    setItem: async (key, value) => await set(key, value),
    removeItem: async (key) => await delDataLoader.load(key),
  };
}

const storage = createAsyncStorage();

export default storage;
