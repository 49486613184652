import { handleSettledPromises } from '@kk/shared/utils/async';
import { removeSearchParams } from '@kk/shared/utils/strings';
import { assertParamsPrescence } from '@kk/shared/utils/validators';
import { useParams, useRouteLoaderData } from 'react-router';
import { caseApi } from '@/api';
import { RequestOpts } from '@/api/api-types';
import { assertNoLoaderErrors } from '@/api/errors';
import { fetchCompanyCasesQuery } from '@/api/hooks/case/useCompanyCases';
import { fetchCompanyQuery } from '@/api/hooks/company/useCompany';
import { fetchEarliestValidFilterDateQuery } from '@/api/hooks/loan/useEarliestValidFilterDate';
import { fetchLoanPortfolioQuery } from '@/api/hooks/loan/useLoanPortfolio';

export type LoaderData = Awaited<ReturnType<typeof loader>>;

export type Params = {
  companyId?: string;
};

export const id = 'customerPage' as const;

// fix for an issue where the query string is included in the params object values,
// resulting in a companyId with a query string in it
const removeQueryStringFromParams = (params: Params) => {
  return Object.keys(params).reduce((acc, key) => {
    return {
      ...acc,
      [key]: removeSearchParams(params[key]),
    };
  }, {} as Params);
};

async function loader({
  request,
  params,
  opts,
}: {
  request: Request;
  params: Params;
  opts?: RequestOpts;
}) {
  assertParamsPrescence(params);
  const { companyId } = removeQueryStringFromParams(params);
  const date = new URL(request.url).searchParams.get('date');
  const data = await handleSettledPromises([
    fetchCompanyQuery({ companyId }, opts),
    fetchCompanyCasesQuery(
      { companyId, queryFilter: caseApi.QueryFilter.All },
      opts,
    ),
    fetchLoanPortfolioQuery({ companyId, date }, opts),
    fetchLoanPortfolioQuery({ companyId, date: undefined }, opts),
    fetchEarliestValidFilterDateQuery(),
  ]);

  assertNoLoaderErrors(id, data);

  const [
    company,
    companyCases,
    loanPortfolio,
    mostRecentLoanPortfolio,
    earliestValidFilterDate,
  ] = data;

  return {
    company,
    companyCases,
    loanPortfolio,
    mostRecentLoanPortfolio,
    earliestValidFilterDate,
  };
}

export const useCustomerPageLoaderData = () => useRouteLoaderData(id);

export const useCustomerPageParams = () => {
  const params = useParams<Params>();
  return removeQueryStringFromParams(params);
};

export default loader;
