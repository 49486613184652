import { handleSettledPromises } from '@kk/shared/utils/async';
import { assertParamsPrescence } from '@kk/shared/utils/validators';
import { useParams, useRouteLoaderData } from 'react-router';
import { RequestOpts } from '@/api/api-types';
import { assertNoLoaderErrors } from '@/api/errors';
import { fetchContractCasesQuery } from '@/api/hooks/case/useContractCases';
import { fetchCompanyQuery } from '@/api/hooks/company/useCompany';
import { fetchLoanQuery } from '@/api/hooks/loan/useLoan';

export type LoaderData = Awaited<ReturnType<typeof loader>>;

export type Params = {
  companyId?: string;
  contractId?: string;
};

export const id = 'loanPage' as const;

async function loader({
  params,
  opts,
}: {
  params: Params;
  opts?: RequestOpts;
}) {
  assertParamsPrescence(params);
  const { contractId, companyId } = params;

  const data = await handleSettledPromises([
    fetchCompanyQuery({ companyId }, opts),
    fetchLoanQuery({ contractId }, opts),
    fetchContractCasesQuery({ contractId, active: true }, opts),
  ]);
  assertNoLoaderErrors(id, data);

  const [company, loanInfo, activeCases] = data;

  return {
    loanInfo,
    company,
    activeCases,
  };
}

export const useLoanPageLoaderData = () => useRouteLoaderData(id);

export const useLoanPageParams = () => useParams<Params>();

export default loader;
