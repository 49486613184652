// TODO: should be removed once the enum values are defined in the API
/** @deprecated to make sure this gets removed in the future */
export enum TemporaryCasePhaseCode {
  AgreementConfirmation = 'AgreementConfirmation',
  AgreementGeneration = 'AgreementGeneration',
  Calculation = 'Calculation',
  Execution = 'Execution',
  IndividualPricing = 'IndividualPricing',
  /** The following values are not yet determined */
  CaseInformation = 'CaseInformation',
  Allocation = 'Allocation',
  Signature = 'Signature',
  Payment = 'Payment',
}
