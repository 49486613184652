import lazyWithPreload from 'react-lazy-with-preload';
import type { ExtendedRouteObject } from '@/routes/types';
import loader, { id, type LoaderData, type Params } from './loader';

const LeasingPage = lazyWithPreload(async () => await import('./index'));

export default {
  id,
  path: '/leasingberegner',
  loader,
  element: <LeasingPage />,
  shouldRevalidate: () => false,
  handle: () => {
    return {
      preload: async () => await Promise.all([LeasingPage.preload()]),
    };
  },
} as const satisfies ExtendedRouteObject<Params, LoaderData>;
