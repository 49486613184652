import clsx from 'clsx';
import { Icon, Size } from '../Icon';
import Tooltip from '../Tooltip';

export interface BaseAvatarProps {
  tooltip?: string | null;
  fullName?: string | null;
  size?: Size;
  className?: string;
  showTooltip?: boolean;
  isCurrentUser?: boolean;
  isExternalUser?: boolean;
}

function BaseAvatar({
  tooltip,
  fullName,
  size = 'medium',
  className,
  showTooltip = false,
  isCurrentUser,
  isExternalUser,
  ...rest
}: BaseAvatarProps) {
  const hasName = fullName && fullName.length > 0;
  const fallback = (
    <Icon
      type="user"
      solid
      size={'small'}
      className={clsx('text-white', {
        'h-2 w-2': size === 'tiny',
        'h-4 w-4': size === 'small',
        'h-6 w-6': size === 'medium',
        'h-7 w-7': size === 'large',
        'h-9 w-9': size === 'xlarge',
      })}
    />
  );

  return (
    <Tooltip tooltip={tooltip ? tooltip : fullName} enabled={showTooltip}>
      <div
        role="img"
        {...rest}
        className={clsx(
          'flex select-none items-center justify-center rounded-full',
          fullName !== ''
            ? 'border-2 border-white'
            : 'bg-neutral-60 border-2 border-white',
          {
            'h-4 w-4': size === 'tiny',
            'h-6 w-6': size === 'small',
            'h-8 w-8': size === 'medium',
            'h-10 w-10': size === 'large',
            'h-12 w-12': size === 'xlarge',
          },
          {
            'bg-blue-110 text-white': isCurrentUser,
            'bg-blue-10 text-blue-110': !isExternalUser,
            'bg-purple-60 text-white': isExternalUser,
          },
          className,
        )}
      >
        <span
          className={clsx(
            {
              'typ-headline-3xs': size === 'tiny' || size === 'small',
              'typ-headline-xs': size === 'medium',
              'typ-headline-sm': size === 'large',
              'typ-headline-lg kunde:typ-headline-md': size === 'xlarge',
            },
            tooltip ? 'font-normal text-black' : '',
          )}
        >
          {size !== 'tiny'
            ? hasName
              ? generateInitials(fullName)
              : fallback
            : hasName
              ? fullName[0]
              : fallback}
        </span>
      </div>
    </Tooltip>
  );
}

export function generateInitials(userName?: string | null): string {
  if (userName?.length === 0) return '';
  const names = userName?.split(' ').map((name) => name.trim());
  if (!names) return '';
  return (
    names.at(0)?.at(0) + (names.length > 1 ? (names.at(-1)?.at(0) ?? '') : '')
  );
}

export default BaseAvatar;
