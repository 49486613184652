import * as React from 'react';
import { InlineNotification } from '@kk/ui/components/Notifications/InlineNotification';
import { TopLineBanner } from '@kk/ui/components/Notifications/TopLineBanner';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import { getOperationId } from '@/api/errors';
import { appInsightsPlugin } from '@/app-insights';
import state from '@/state';

export class AppInsightsErrorBoundary extends React.Component<{
  onError: (error: Error | undefined) => React.ReactNode;
  children: React.ReactNode;
  appInsights: ReactPlugin;
}> {
  state: { hasError: boolean; error: Error | undefined } = {
    hasError: false,
    error: undefined,
  };

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    this.setState({ hasError: true, error: error });
    if (import.meta.env.MODE === 'production') {
      this.props.appInsights.trackException({
        error: error,
        exception: error,
        severityLevel: SeverityLevel.Error,
        properties: { ...info },
      });
    }
  }

  render() {
    if (this.state.hasError) {
      const { onError } = this.props;
      return typeof onError === 'function'
        ? onError(this.state.error)
        : React.createElement(onError);
    }

    return this.props.children;
  }
}

export function ErrorBoundary({
  children,
  inline = true,
  className,
  silent,
}: {
  children?: React.ReactNode;
  inline?: boolean;
  className?: string;
  silent?: boolean;
}) {
  const { devtoolsEnabled } = useSnapshot(state);
  const { reset } = useQueryErrorResetBoundary();
  const { t } = useTranslation();

  return (
    <AppInsightsErrorBoundary
      onError={(error) => {
        const operationId = error ? getOperationId(error) : undefined;
        const operationIdString = operationId
          ? ` (OperationId: ${operationId})`
          : '';
        if (devtoolsEnabled || import.meta.env.MODE === 'test')
          console.error(error?.message);
        if (silent) return null;
        if (inline) {
          return (
            <InlineNotification
              data-testid="inline-error"
              type="error"
              size="medium"
              headline={t('error.component.title')}
              message={
                (devtoolsEnabled
                  ? error?.message
                  : t('error.component.message')) + operationIdString
              }
              className={className}
              onDismiss={() => {
                reset();
              }}
            />
          );
        }
        const toplineBanner = (
          <TopLineBanner
            data-testid="error-banner"
            type="error"
            message={t('error.api.title')}
            label={
              (devtoolsEnabled ? error?.message : t('error.api.message')) +
              operationIdString
            }
            className={className}
            onDismiss={() => {
              reset();
              window.location.reload();
            }}
          />
        );
        const portalTarget = document.getElementById('error-portal');
        if (portalTarget) {
          return createPortal(toplineBanner, portalTarget);
        }
        return toplineBanner;
      }}
      appInsights={appInsightsPlugin}
    >
      {children}
    </AppInsightsErrorBoundary>
  );
}
