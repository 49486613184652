import React, { useCallback, useEffect, useId, useRef, useState } from 'react';
import clsx from 'clsx';
import { createPortal } from 'react-dom';
import { twMerge } from 'tailwind-merge';
import { proxy, useSnapshot } from 'valtio';

const state = proxy({ tooltipsVisible: false });

export interface TooltipProps {
  children: React.ReactElement;
  enabled?: boolean;
  tooltip: React.ReactNode | string;
  className?: string;
}

const MAX_WIDTH = 238;

function TooltipPortal({
  children,
  visible,
  id,
}: {
  children?: React.ReactNode;
  visible?: boolean;
  id: string;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const { tooltipsVisible } = useSnapshot(state);

  const mouseMoveHandler = useCallback(
    (event: MouseEvent) => {
      if (ref.current && visible) {
        ref.current.style.top = event.pageY + 'px';
        ref.current.style.left = event.pageX + 'px';

        const distanceToRight = window.innerWidth - event.pageX;
        const threshold = 5;
        const exceedsScreenRight = distanceToRight - threshold * 2 < MAX_WIDTH;
        if (exceedsScreenRight) {
          ref.current.style.right = '0px';
          ref.current.style.transformOrigin = 'top left';
          ref.current.style.left = 'auto';
        } else {
          ref.current.style.transformOrigin = 'top right';
          ref.current.style.right = 'auto';
        }
        ref.current.style.transform = `translate(${
          exceedsScreenRight ? '-' + distanceToRight + 'px' : threshold + 'px'
        }, calc(-100% - ${threshold}px)`;
      }
    },
    [visible],
  );

  useEffect(() => {
    document.addEventListener('mousemove', mouseMoveHandler, { passive: true });
    return () => {
      document.removeEventListener('mousemove', mouseMoveHandler);
    };
  }, [mouseMoveHandler, visible]);

  return createPortal(
    <div
      role="tooltip"
      className={clsx(
        'tooltip',
        'max-w-[' + MAX_WIDTH + 'px]',
        'hyphens-auto break-words',
      )}
      ref={ref}
      id={id}
      data-testid="tooltip"
      aria-hidden={!visible || !tooltipsVisible}
      data-is-visible={visible && tooltipsVisible ? 'true' : 'false'}
    >
      {children}
    </div>,
    document.getElementById('tooltip-portal') ?? document.body,
  );
}

let hoverTimeout: number | null = null;

// TODO: refactor to use popper.js for automatic positioning
export function Tooltip(props: TooltipProps) {
  const { enabled = true } = props;
  const [isVisible, setIsVisible] = useState(false);
  const id = useId();

  const handleMouseEnter = useCallback(() => {
    setIsVisible(true);
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
    }
    hoverTimeout = window.setTimeout(() => {
      state.tooltipsVisible = true;
      hoverTimeout = null;
    }, 300);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsVisible(false);
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
    }
    hoverTimeout = window.setTimeout(() => {
      state.tooltipsVisible = false;
      hoverTimeout = null;
    }, 1500);
  }, []);

  if (!props.tooltip) return props.children;
  return (
    <>
      <TooltipPortal visible={isVisible && enabled} id={id}>
        {props.tooltip}
      </TooltipPortal>
      <span
        aria-describedby={id}
        className={twMerge('contents', props.className)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {props.children}
      </span>
    </>
  );
}

export default Tooltip;
