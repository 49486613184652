import { queryOptions, useQuery } from '@tanstack/react-query';
import { loanApi } from '@/api';
import { RequestOpts } from '@/api/api-types';
import queryClient from '@/api/queryClient';

export type Response = Awaited<
  ReturnType<typeof loanApi.putCreatestandalonecalculationpath>
>;

export type PutCreateStandaloneCalculationQueryParams = {
  loanNo?: number;
};

export function createStandaloneCalculationpathQuery(
  params: PutCreateStandaloneCalculationQueryParams,
  opts: RequestOpts = {},
) {
  const queryKey = ['create-standalone-calculation'];
  return queryOptions({
    queryKey,
    queryFn: ({ signal }) => {
      return loanApi.putCreatestandalonecalculationpath(params, {
        ...opts,
        signal,
      });
    },
    staleTime: 0,
  });
}

export async function fetchCreateStandaloneCalculationpath(
  params: PutCreateStandaloneCalculationQueryParams,
  opts: RequestOpts = {},
) {
  return queryClient.fetchQuery(
    createStandaloneCalculationpathQuery(params, opts),
  );
}

export function useStandaloneCalculationpath(
  params: PutCreateStandaloneCalculationQueryParams,
  options: Omit<
    ReturnType<typeof createStandaloneCalculationpathQuery>,
    'queryKey'
  >,
) {
  const query = createStandaloneCalculationpathQuery(params);
  return useQuery({
    ...query,
    ...options,
    initialDataUpdatedAt: queryClient.getQueryState(query.queryKey)
      ?.dataUpdatedAt,
  });
}
