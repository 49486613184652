/**
 *
 * checks if a value is an object
 * @param obj the value to check
 * @returns {boolean} true if the value is an object, false otherwise
 */
export function isObject(obj: unknown): obj is Object {
  return obj !== null && typeof obj === 'object';
}

/**
 * Checks if an array of values contains any instances of Error.
 *
 * @template T - Tuple or array type with possible Error instances.
 * @param {T} data - The array of values to check.
 * @returns {boolean} - Returns true if no instances of Error are found, otherwise false.
 */
export function hasNoErrors<T extends [...(Error | T[number])[]]>(
  data: T,
): data is { [K in keyof T]: Exclude<T[K], Error> } {
  return data.findIndex((value) => value instanceof Error) === -1;
}

export function isErrorResponse(response: unknown) {
  return isObject(response) && 'error' in response;
}

/**
 * Checks if a value is nullish (null, undefined, or empty string).
 *
 * @param {unknown} obj - The value to check.
 * @returns {boolean} Returns `true` if the value is nullish, `false` otherwise.
 */
export function isNullish(obj: unknown): obj is null | undefined | '' {
  return obj === null || obj === undefined || obj === '';
}

/**
 * Ensures that a switch statement has been exhaustively checked.
 * Throws an error if a value that isn't expected is passed.
 *
 * @param value - The value that was not expected.
 * @throws Will throw an error if the function is invoked.
 */
export function exhaustiveMatchingGuard(value: never): never {
  throw new Error(`Non-exhaustive switch case: "${value}" was not handled.`);
}
