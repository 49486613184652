/**
 This file exports APIs related to different modules of the application. 
 It sets default headers, URLs, and fetch configurations for each module's API requests. 
*/

import * as caseApi from '@kk/api/modules/case';
import * as companyApi from '@kk/api/modules/company';
import * as loanApi from '@kk/api/modules/loan';
import * as orchestrationApi from '@kk/api/modules/orchestration';
import * as searchApi from '@kk/api/modules/search';
import { fetchWithToken, headers } from '@/api/options';
import { ClientConfig } from '@/client-config';

// Search {{{
searchApi.defaults.baseUrl = ClientConfig.apiUrl + '/search/v1';
searchApi.defaults.headers = headers;
searchApi.defaults.fetch = fetchWithToken;
// }}}

// Loan {{{
loanApi.defaults.baseUrl = ClientConfig.apiUrl + '/loan/v1';
loanApi.defaults.headers = headers;
loanApi.defaults.fetch = fetchWithToken;
// }}}

// Company {{{
companyApi.defaults.baseUrl = ClientConfig.apiUrl + '/company/v1';
companyApi.defaults.headers = headers;
companyApi.defaults.fetch = fetchWithToken;
// }}}

// Case {{{
caseApi.defaults.baseUrl = ClientConfig.apiUrl + '/case/v1';
caseApi.defaults.headers = headers;
caseApi.defaults.fetch = fetchWithToken;
// }}}

// Orchestration {{{
orchestrationApi.defaults.baseUrl = ClientConfig.apiUrl + '/orchestration/v1';
orchestrationApi.defaults.headers = headers;
orchestrationApi.defaults.fetch = fetchWithToken;
// }}}

export { searchApi, companyApi, caseApi, loanApi, orchestrationApi };
