/**
 * Returns a new string with the first letter capitalized.
 */
export const capitalizeFirstLetter = (
  str: string | null | undefined,
  locale = globalThis?.navigator?.language,
) => {
  if (!str) return '';

  const first = str?.at(0)?.toLocaleUpperCase(locale);
  const rest = str.slice(1);

  return first + rest;
};

export const removeSearchParams = (url: string | null | undefined) => {
  const urlWithoutSearchParams = url?.split('?').at(0);
  return urlWithoutSearchParams || url;
};
