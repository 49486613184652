import { handleSettledPromises } from '@kk/shared/utils/async';
import { assertParamsPrescence } from '@kk/shared/utils/validators';
import { useParams, useRouteLoaderData } from 'react-router';
import { RequestOpts } from '@/api/api-types';
import { assertNoLoaderErrors, LoaderError } from '@/api/errors';
import { fetchCaseQuery } from '@/api/hooks/case/useCase';
import { fetchCaseAssigneesQuery } from '@/api/hooks/case/useCaseAssignees';
import { fetchDocumentByRecordIdQuery } from '@/api/hooks/case/useDocumentByRecordId';
import { fetchCompanyQuery } from '@/api/hooks/company/useCompany';
import { fetchCalculationFromStorageQuery } from '@/api/hooks/loan/useCalculationFromStorage';
import { fetchLoanQuery } from '@/api/hooks/loan/useLoan';
import { isAgreementCandidate } from '@/api/predicates/caseCalculation';
import { isGeneratedContract } from '@/api/predicates/caseRecord';

export type Params = {
  caseId?: string;
  contractId?: string;
  companyId?: string;
};

export type LoaderData = Awaited<ReturnType<typeof loader>>;

export const id = 'contractGenerationPage' as const;

async function loader({
  params,
  opts,
}: {
  params: Params;
  opts?: RequestOpts;
}) {
  assertParamsPrescence(params);

  const { caseId, contractId, companyId } = params;
  const data = await handleSettledPromises([
    fetchCompanyQuery({ companyId }, opts),
    fetchLoanQuery({ contractId }, opts),
    fetchCaseQuery({ caseId }, opts),
    fetchCaseAssigneesQuery({ caseId }, opts),
  ]);

  assertNoLoaderErrors(id, data);
  const [company, loanInfo, loanCase, caseAssignees] = data;

  const agreementCandidate = loanCase.calculations?.find(isAgreementCandidate);

  if (!agreementCandidate) throw new LoaderError(id, data);

  const contractAgreementRecord =
    loanCase.caseRecords?.find(isGeneratedContract);

  const relatedData = await handleSettledPromises([
    fetchCalculationFromStorageQuery({
      calculationPath: agreementCandidate.calculationPath,
    }),
    fetchDocumentByRecordIdQuery({
      recordId: contractAgreementRecord?.recordId,
    }),
  ]);
  assertNoLoaderErrors(id, relatedData);

  const [calculationData, contractAgreementDocument] = relatedData;

  return {
    calculationData,
    loanInfo,
    company,
    loanCase,
    caseAssignees,
    contractAgreementDocument,
  };
}

export const useContractGenerationPageLoaderData = () => useRouteLoaderData(id);

export const useContractGenerationPageParams = () => useParams<Params>();

export default loader;
