import { Translation } from 'react-i18next';
import lazyWithPreload from 'react-lazy-with-preload';
import { caseApi } from '@/api';
import BreadcrumbMatches from '@/components/BreadcrumbMatches';
import { ExtendedRouteObject } from '../types';
import loader, { id, type LoaderData, type Params } from './loader';

const IndividualPriceSettingPage = lazyWithPreload(
  async () => await import('./index'),
);
const CasePage = lazyWithPreload(async () => await import('../CasePage'));

function preloadRelatedRouteComponents() {
  CasePage.preload();
}

export default {
  id,
  path: '/kunde/:companyId/aftale/:contractId/sag/:caseId/individuel-prisfastsaettelse',
  loader: (args) => {
    window.requestIdleCallback(preloadRelatedRouteComponents);
    return loader(args);
  },
  element: <IndividualPriceSettingPage />,
  handle: ({ params, data }) => {
    const phase = data?.loanCase.phases?.find(
      (phase) =>
        phase.casePhaseCode === caseApi.CasePhaseCode.IndividualPricing,
    );
    return {
      preload: async () =>
        await Promise.allSettled([
          IndividualPriceSettingPage.preload(),
          loader({ params, opts: { priority: 'low' } }),
        ]).then(preloadRelatedRouteComponents),
      breadcrumb: {
        tag:
          phase && data?.loanCase ? (
            <BreadcrumbMatches.PhaseStatusTag
              phase={phase}
              loanCase={data.loanCase}
            />
          ) : null,
        label: (
          <span className="flex">
            <Translation>{(t) => t('contract.priceSetting.title')}</Translation>
          </span>
        ),
      },
    };
  },
} as const satisfies ExtendedRouteObject<Params, LoaderData>;
