import { isObject } from '@kk/shared/utils/type-guards';
import { queryOptions, useQuery } from '@tanstack/react-query';
import ms from 'ms';
import { loanApi, orchestrationApi } from '@/api';
import { RequestOpts } from '@/api/api-types';
import { LOAN_SCOPES } from '@/api/auth/permissions';
import queryClient from '@/api/queryClient';

const method = loanApi.getFetchcalculationfromstorageByCalculationPath;
export type Response = Awaited<ReturnType<typeof method>>;

type GetCalculationQueryParams = {
  calculationPath?: string | null;
  loanNo?: number;
};

export function getCalculationQuery(
  params?: GetCalculationQueryParams,
  opts: RequestOpts = {},
) {
  const queryKey = [
    LOAN_SCOPES.getCalculation,
    params?.calculationPath,
    params?.loanNo?.toString(),
  ].filter(Boolean);
  return queryOptions({
    queryKey,
    queryFn: ({ signal }) => {
      if (!params?.calculationPath)
        throw new TypeError('No calculationPath provided');
      return method(
        params.calculationPath,
        { loanNo: params.loanNo },
        { ...opts, signal },
      );
    },
    retry: false,
    staleTime: ms('7d'),
    gcTime: ms('10m'),
    initialDataUpdatedAt: queryClient.getQueryState(queryKey)?.dataUpdatedAt,
  });
}

export async function fetchCalculationFromStorageQuery(
  params?: GetCalculationQueryParams,
  opts: RequestOpts = {},
) {
  return queryClient.fetchQuery(getCalculationQuery(params, opts));
}

export function isEventDescription(
  str?: string | null,
): str is orchestrationApi.EventDescriptionCode {
  return (
    !!str && Object.values(orchestrationApi.EventDescriptionCode).includes(str)
  );
}

export function isCalculationInfoResponse(object: unknown): object is Response {
  return (
    isObject(object) &&
    object.hasOwnProperty('calculationDateTime') &&
    object.hasOwnProperty('StatusCode') === false
  );
}

export function useCalculationFromStorage(
  params?: GetCalculationQueryParams,
  options: Omit<ReturnType<typeof getCalculationQuery>, 'queryKey'> = {},
) {
  const query = getCalculationQuery(params);
  return useQuery({ ...query, ...options, enabled: !!params?.calculationPath });
}
