import {
  defaultDateFormat,
  formatDate,
  formatDateDistanceToNow,
  isValidDate,
} from '@kk/shared/utils/date';
import { formatCompact, formatCurrency } from '@kk/shared/utils/numbers';
import { capitalizeFirstLetter } from '@kk/shared/utils/strings';
import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';

const missingMap: Record<string, boolean> = {};

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

export function initI18n(options?: InitOptions) {
  i18n.use(initReactI18next).init({
    returnNull: false,
    fallbackLng: 'da',
    load: 'currentOnly',
    react: { useSuspense: false },
    interpolation: {
      escapeValue: false, // react is already safe from xss
      format: (value, format) => {
        if (!value) return null;
        switch (format) {
          case 'date':
            return isValidDate(value)
              ? formatDate(new Date(value), defaultDateFormat)
              : value;

          case 'dateTime':
            return isValidDate(value)
              ? formatDate(new Date(value), `${defaultDateFormat} 'kl.' p`)
              : value;

          case 'timeSince':
            return formatDateDistanceToNow(value);

          case 'currency':
            return formatCurrency(value);

          case 'capitalized':
            return capitalizeFirstLetter(value);

          case 'compact':
            return formatCompact(value);

          default:
            return value;
        }
      },
    },
    missingKeyHandler: (lng, ns, key) => {
      const id = lng + ns + key;
      if (!missingMap[id]) {
        console.warn(`Missing i18next key: ${key}`);
        missingMap[id] = true;
      }
    },
    ...options,
  });
  return i18n;
}

export default i18n;
