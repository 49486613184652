import React from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { Icon, IconType } from '../Icon';

export interface TagProps {
  children: React.ReactNode;
  className?: string;
  iconClassName?: string;
  icon?: IconType;
  solid?: boolean;
}

export function Tag(props: TagProps) {
  return (
    <div
      className={twMerge(
        'typ-headline-3xs bg-neutral-10 max-w-contents text-neutral-110 flex w-fit rounded-lg px-2 py-0.5 empty:hidden',
        'text-nowrap break-keep',
        props.className,
      )}
    >
      {props.icon ? (
        <Icon
          type={props.icon}
          size="tiny"
          solid={props.solid}
          className={clsx('-ml-1 mr-1', props.iconClassName)}
        />
      ) : null}
      {props.children ? <span>{props.children}</span> : null}
    </div>
  );
}

export default Tag;
