import {
  LinkButton,
  LinkButtonProps,
} from '@kk/ui/components/Button/LinkButton';
import { NotificationType, SizeType } from '@kk/ui/constants';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { BaseButton } from '../Button';
import { Icon, IconType } from '../Icon';

export type InlineNotificationProps = {
  type?: NotificationType;
  size?: SizeType;
  message?: string | React.ReactNode;
  headline?: string;
  label?: string;
  link?: LinkButtonProps;
  linkTop?: LinkButtonProps;
  className?: string;
  children?: React.ReactNode;
  onDismiss?: () => void;
};

const baseClasses = clsx('relative text-neutral-200 p-2 w-full rounded');

const theme: Record<NotificationType, string> = {
  default: clsx('bg-neutral-5'),
  info: clsx('bg-blue-5 border-blue-40'),
  success: clsx('bg-green-5 border-green-40'),
  warning: clsx('bg-orange-5 border-orange-40'),
  error: clsx('bg-red-5 border-red-40'),
};

const sizes: Record<SizeType, string> = {
  tiny: '', // clsx('max-w-[320px]'),
  small: '', // clsx('max-w-[400px]'),
  medium: clsx('max-w-[600px]'),
  large: clsx('max-w-[752px]'),
  xlarge: clsx('max-w-[968px]'),
  full: clsx('w-full'),
};

const icon: Record<NotificationType, IconType | undefined> = {
  default: undefined,
  success: 'check-circle',
  info: 'info',
  warning: 'alert-triangle',
  error: 'alert-diamond',
};

const iconColor: Record<NotificationType, string> = {
  default: '',
  info: clsx('text-blue-100'),
  success: clsx('text-green-100'),
  warning: clsx('text-orange-100'),
  error: clsx('text-red-100'),
};

export function InlineNotification({
  type = 'default',
  size = 'small',
  headline,
  label,
  linkTop,
  link,
  message,
  className,
  onDismiss,
  children,
  ...rest
}: InlineNotificationProps) {
  return (
    <div
      role={type === 'error' ? 'alert' : 'banner'}
      className={twMerge(baseClasses, sizes[size], theme[type], className)}
      {...rest}
    >
      <div className="absolute right-4 flex items-center justify-center space-x-2.5">
        {linkTop ? (
          <LinkButton
            {...linkTop}
            padding=""
            className={clsx(
              'typ-link-xs font-light',
              ['warning', 'error', 'success'].includes(type)
                ? '!text-neutral-200'
                : '',
            )}
          />
        ) : null}
        {typeof onDismiss === 'function' ? (
          <BaseButton onClick={onDismiss}>
            <Icon type="cross" size="tiny" />
          </BaseButton>
        ) : null}
      </div>
      <div className="flex flex-row space-x-2">
        {icon[type] !== undefined ? (
          <div className={clsx(iconColor[type], 'self-start')}>
            <Icon
              type={icon[type]}
              size={size === 'small' ? 'tiny' : 'small'}
              solid
            />
          </div>
        ) : null}
        <div className="flex-1">
          {headline ? (
            <span
              className={clsx(
                'block',
                size === 'small' ? 'typ-headline-2xs' : 'typ-headline-sm',
              )}
            >
              {headline}
            </span>
          ) : null}
          {label ? (
            <span className="typ-ui text-neutral-100">{label}</span>
          ) : null}
          {message ? <p className="typ-ui-sm mt-1">{message}</p> : null}
          {link ? (
            <LinkButton
              {...link}
              padding=""
              className={clsx(
                'typ-link-xs mt-2 font-light',
                ['warning', 'error', 'success'].includes(type)
                  ? '!text-neutral-200'
                  : '',
              )}
            />
          ) : null}
          {children}
        </div>
      </div>
    </div>
  );
}
