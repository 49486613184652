import { IPublicClientApplication, SilentRequest } from '@azure/msal-browser';
import semaphore from 'semaphore';
import { loginRequest } from '@/api/auth/auth.config';
import msalInstance from '@/api/auth/msalInstance';
import { getOperationId } from '@/api/errors';
import { ClientConfig } from '@/client-config';
import state from '@/state';
import { handleTokenError } from './errors';

export const headers: Record<string, string> = {
  'cache-control': 'no-cache',
  'Ocp-Apim-Subscription-Key': ClientConfig.subscriptionKey,
};

// Cache for access tokens to prevent unnecessary calls to MSAL
type AccessTokenCache = {
  token?: string;
  expiration?: Date | null;
};
const accessTokenCache: AccessTokenCache = {};

// Semaphore to ensure only one thread at a time is acquiring a token
const tokenAcquisitionSemaphore = semaphore(1);

// Track exceptions in App Insights and reject the promise
function rejectAndTrackException(error: Error) {
  if (state.devtoolsEnabled) {
    console.error(error);
  }
  if (import.meta.env.DEV) return Promise.reject(error);
  window.requestIdleCallback(
    () => {
      window.requestAnimationFrame(() => {
        const operationId = getOperationId(error);
        import('@/app-insights').then(({ appInsights }) => {
          appInsights.trackException(
            { error },
            {
              operationId,
            },
          );
        });
      });
    },
    { timeout: 1000 },
  );
  return Promise.reject(error);
}

/**
 * Fetches data from an API endpoint with a bearer token in the Authorization header.
 *
 * @async
 * @function
 * @param {RequestInfo | URL} input - The URL to fetch or a Request object.
 * @param {RequestInit} [init] - The optional RequestInit object that includes options like the request method (GET, POST, etc.) and request headers.
 * @returns {Promise<Response>} - A Promise that resolves to the Response object returned by the fetch call.
 */
export async function fetchWithToken(
  input: RequestInfo | URL,
  init?: RequestInit,
): Promise<Response> {
  const headers = new Headers(init?.headers);
  const withToken = (token: string) => {
    headers.set('Authorization', `Bearer ${token}`);
    return fetch(input, {
      ...init,
      headers,
    });
  };

  if (process.env.NODE_ENV === 'test' || window['STORYBOOK_ENV'] === 'react') {
    // Add a test access token to the headers when in test or storybook mode
    return withToken('test');
  }

  try {
    const { token, expiration } = accessTokenCache;
    // Check if we have a valid access token in the cache
    if (token && expiration && Date.now() < expiration.getTime()) {
      // Add the access token to the headers
      return withToken(token);
    }

    // Wait for token acquisition to complete if another request is already acquiring a token
    await new Promise<void>((resolve) => {
      tokenAcquisitionSemaphore.take(() => resolve());
    });

    try {
      // Recheck if the token was acquired by another request while waiting for the semaphore
      if (
        accessTokenCache.token &&
        accessTokenCache.expiration &&
        Date.now() < accessTokenCache.expiration.getTime()
      ) {
        // Add the access token to the headers
        return withToken(accessTokenCache.token);
      }

      // otherwise, get a new access token
      const instance: IPublicClientApplication = msalInstance;
      const account = instance.getActiveAccount();
      if (!account) throw new Error('No active account');
      const accessTokenRequest: SilentRequest = {
        account,
        scopes: loginRequest.scopes,
      };

      // Get an access token silently from MSAL
      const accessTokenResponse =
        await instance.acquireTokenSilent(accessTokenRequest);

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!accessTokenResponse) {
        instance.clearCache();
        instance.logoutRedirect({
          account,
          postLogoutRedirectUri: window.location.origin,
        });
        throw new Error('Token acquisition failed');
      }
      const accessToken = accessTokenResponse.accessToken;
      accessTokenCache.expiration = accessTokenResponse.expiresOn;
      accessTokenCache.token = accessToken;

      return withToken(accessToken).catch(rejectAndTrackException);
    } finally {
      tokenAcquisitionSemaphore.leave();
    }
  } catch (error) {
    handleTokenError(error);
    return rejectAndTrackException(error);
  }
}

export type DecodedToken = {
  sub?: string; // subject
  iss?: string; // issuer
  aud?: string | string[]; // audience
  exp?: number; // expiration time
  nbf?: number; // "not before" time
  iat?: number; // issued at time
  jti?: string; // JWT ID
  roles: string[]; // account roles
  // [key: string]: any; // additional properties
  OnPremSamAccountName?: string;
  name?: string;
  preferred_username?: string;
};

const decoder = new TextDecoder();
/**
 * Parses a JWT token and returns the decoded payload.
 *
 * @param {string} token - The JWT token to be parsed.
 * @returns {DecodedToken} - The decoded token object.
 * @throws {Error} - Throws an error if the token structure is invalid.
 */
export function parseJwt(token: string): DecodedToken {
  const base64Url = token.split('.')[1];
  if (!base64Url) {
    throw new Error('Invalid token structure');
  }
  const base64 = base64Url.replace(/[-_]/g, (match) =>
    match === '-' ? '+' : '/',
  );

  const base64Decoded = Uint8Array.from(atob(base64), (c) => c.charCodeAt(0));
  const jsonPayload = decoder.decode(base64Decoded);

  return JSON.parse(jsonPayload) as DecodedToken;
}
