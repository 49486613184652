import { NotificationType } from '@kk/ui/constants';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { BaseButton } from '../Button';
import { Icon } from '../Icon';

export type TopLineBannerProps = {
  type: NotificationType;
  message: string;
  label?: string;
  className?: string;
  onDismiss?: () => void;
};

const baseClasses = clsx('flex items-center py-3 px-4');

const theme: Record<NotificationType, string> = {
  default: clsx('bg-neutral-100 text-white'),
  info: clsx('bg-neutral-100 text-white'),
  success: clsx('bg-green-100 text-white'),
  warning: clsx('bg-orange-100 text-neutral-200'),
  error: clsx('bg-red-100 text-white'),
};

export function TopLineBanner({
  message,
  type = 'default',
  label,
  className,
  onDismiss,
  ...rest
}: TopLineBannerProps) {
  return (
    <div
      role={['warning', 'error'].includes(type) ? 'alert' : 'banner'}
      className={twMerge(clsx(baseClasses, theme[type]), className)}
      {...rest}
    >
      <span className="typ-headline-xs">{message}</span>
      <span className="flex-1" />
      {label ? <span className="typ-ui">{label}</span> : null}
      {typeof onDismiss === 'function' ? (
        <BaseButton className="ml-3" onClick={onDismiss}>
          <Icon type="cross" size="tiny" />
        </BaseButton>
      ) : null}
    </div>
  );
}
