import lazyWithPreload from 'react-lazy-with-preload';
import type { ExtendedRouteObject } from '@/routes/types';
import loader, { id, type LoaderData, type Params } from './loader';

const StandaloneCalculatorPage = lazyWithPreload(
  async () => await import('./index'),
);

export default {
  id,
  path: '/beregner',
  loader,
  element: <StandaloneCalculatorPage />,
  shouldRevalidate: () => false,
  handle: () => {
    return {
      preload: async () =>
        await Promise.all([StandaloneCalculatorPage.preload()]),
    };
  },
} as const satisfies ExtendedRouteObject<Params, LoaderData>;
