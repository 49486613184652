import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { Icon } from '../Icon';

export type BreadcrumbItem = {
  label: React.ReactNode;
  to?: string;
  tag?: React.ReactNode;
};

export interface BreadcrumbsProps {
  truncate?: boolean;
  items?: BreadcrumbItem[];
}

export function Breadcrumbs({ items, truncate }: BreadcrumbsProps) {
  const [truncated, setTruncated] = useState(truncate ?? false);
  useEffect(() => {
    setTruncated(truncate ?? false);
  }, [truncate]);
  if (!items || items.length < 2) return null;

  if (truncated && items.length > 3) {
    const firstItem = items[0];
    const lastItem = items[items.length - 1];
    return (
      <nav aria-label="breadcrumb">
        <ol className="typ-body-xs flex list-none items-center">
          <li className="flex items-center">
            <Link
              to={firstItem?.to || ''}
              className="hover:text-blue-60 focus:text-blue-60 font-normal text-neutral-100 transition-colors"
            >
              {firstItem?.label}
            </Link>
            <div className="text-neutral-60 px-1.5">
              <Icon type="chevron-right-large" size="tiny" />
            </div>
          </li>
          <li className="flex items-center">
            <button onClick={() => setTruncated(false)}>
              <Icon
                type="more"
                size="small"
                className="text-neutral-60 focus:text-blue-60 hover:text-blue-60 translate-y-1 transition-colors"
              />
            </button>
            <div className="text-neutral-60 px-1.5">
              <Icon type="chevron-right-large" size="tiny" />
            </div>
          </li>
          <li>
            <Link
              to={lastItem?.to ?? '#'}
              className="font-semibold text-neutral-200"
              aria-current="page"
            >
              {lastItem?.label}
            </Link>
            {lastItem?.tag ? lastItem.tag : null}
          </li>
        </ol>
      </nav>
    );
  }
  return (
    <nav aria-label="breadcrumb">
      <ol className="typ-body-xs flex list-none">
        {items.map((item, index, array) => (
          <li className="flex items-center overflow-hidden" key={index}>
            <Link
              to={item.to ?? '#'}
              aria-current={index === array.length - 1 ? 'page' : undefined}
              className={clsx(
                'overflow-hidden truncate transition-colors',
                index === array.length - 1
                  ? 'font-semibold text-neutral-200'
                  : 'hover:text-blue-60 focus:text-blue-60 font-normal text-neutral-100',
              )}
            >
              {item.label}
            </Link>
            {item.tag ? item.tag : null}
            {index !== array.length - 1 ? (
              <div className="text-neutral-60 px-1.5">
                <Icon type="chevron-right-large" size="tiny" />
              </div>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  );
}

export default Breadcrumbs;
