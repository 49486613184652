import React from 'react';

const spinner = (size = 8) => (
  <svg
    data-testid="spinner"
    width={size}
    height={size}
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <circle
        cx="12"
        cy="12"
        r="9.5"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
      >
        <animate
          attributeName="stroke-dasharray"
          dur="1.5s"
          calcMode="spline"
          values="0 150;42 150;42 150;42 150"
          keyTimes="0;0.475;0.95;1"
          keySplines="0.42,0,0.58,1;0.42,0,0.58,1;0.42,0,0.58,1"
          repeatCount="indefinite"
        />
        <animate
          attributeName="stroke-dashoffset"
          dur="1.5s"
          calcMode="spline"
          values="0;-16;-59;-59"
          keyTimes="0;0.475;0.95;1"
          keySplines="0.42,0,0.58,1;0.42,0,0.58,1;0.42,0,0.58,1"
          repeatCount="indefinite"
        />
      </circle>
      <animateTransform
        attributeName="transform"
        type="rotate"
        dur="2s"
        values="0 12 12;360 12 12"
        repeatCount="indefinite"
      />
    </g>
  </svg>
);

export const sizes = ['xsmall', 'small', 'medium', 'large', 'xlarge'] as const;
type SpinnerSize = (typeof sizes)[number];

export type SpinnerProps = {
  size?: SpinnerSize;
  className?: string;
  style?: React.CSSProperties;
};

function getSpinner(size: SpinnerSize): React.ReactElement {
  switch (size) {
    case 'xsmall':
      return spinner(8);
    case 'small':
      return spinner(16);
    case 'medium':
      return spinner(24);
    case 'large':
      return spinner(48);
    case 'xlarge':
      return spinner(96);
  }
}

export function Spinner(props: SpinnerProps) {
  return (
    <div className={props.className} style={props.style}>
      <div className="inline-block">{getSpinner(props.size ?? 'small')}</div>
    </div>
  );
}

export default Spinner;
