/**
 * This file exports a `ClientConfig` class that provides configuration
 * options used by the client. These values are sourced from cookies if
 * present, otherwise from environment variables.
 *
 * The `ClientConfiguration` interface defines the properties that can be
 * set in the configuration object.
 *
 * The `getCookie` function is used internally to retrieve the config object
 * from a cookie, and then remove the cookie from the browser once it's been
 * read. This is done to prevent the cookie from being sent to the server.
 *
 * @remarks
 * - the cookie is populated from a server-side cookie in `server.ts`.
 */

import z from 'zod';
import { generateErrorMessage } from 'zod-error';
import configSchema from '@/schemas/config';
import envSchema, { removePrefix } from '@/schemas/env';

export type ClientConfiguration = z.infer<typeof configSchema>;

export class ClientConfig {
  static authAuthority: string;
  static authClientId: string;
  static authScope: string;
  static authRedirect: string;
  static apiUrl: string;
  static subscriptionKey: string;
  static applicationInsightsConnectionString: string;

  static {
    if (import.meta.env.DEV) {
      // Read from environment variables when running in development
      const env = envSchema.parse(removePrefix(import.meta.env));
      this.authAuthority = env.AUTH_AUTHORITY;
      this.authClientId = env.AUTH_CLIENT;
      this.authScope = env.AUTH_SCOPE;
      this.authRedirect = env.AUTH_REDIRECT;
      this.apiUrl = env.API_URL;
      this.subscriptionKey = env.SUBSCRIPTION_KEY;
      this.applicationInsightsConnectionString =
        env.APPLICATIONINSIGHTS_CONNECTION_STRING;
    } else {
      // Read from cookie when running in production
      const config = getCookie('clientConfig');
      if (config) {
        this.authAuthority = config.authAuthority;
        this.authClientId = config.authClientId;
        this.authScope = config.authScope;
        this.authRedirect = config.authRedirect;
        this.apiUrl = config.apiUrl;
        this.subscriptionKey = config.subscriptionKey;
        this.applicationInsightsConnectionString =
          config.applicationInsightsConnectionString;
      } else {
        throw new Error('No client config found in cookie');
      }
    }

    const validation = configSchema.safeParse({ ...this });
    if (!validation.success) {
      // eslint-disable-next-line no-console
      console.error('Invalid config');
      // eslint-disable-next-line no-console
      console.warn(
        generateErrorMessage(validation.error.issues, {
          delimiter: { error: '\\n' },
        }),
      );
    }
  }
}

function getCookie(cookieName: string): ClientConfiguration | null {
  if (typeof document === 'undefined') {
    return null;
  }

  const cookies = document.cookie;

  const startPosition = cookies.search(`${cookieName}=`);

  if (startPosition === -1) {
    return null;
  }

  let endPosition = cookies.indexOf(';', startPosition);
  if (endPosition === -1) {
    endPosition = cookies.length;
  }

  const cookie = decodeURIComponent(
    cookies.substring(startPosition, endPosition).split('=').at(1) ?? '',
  );

  return (
    cookie.charAt(0) === '{' ? JSON.parse(cookie) : cookie
  ) as ClientConfiguration;
}
