import clsx from 'clsx';
import { IconType, Size } from './icon.types';
import outline from './spritesheet/outline.svg';
import solidIcons from './spritesheet/solid.svg';

export type IconProps = {
  type?: IconType;
  size?: Size;
  style?: React.CSSProperties;
  solid?: boolean;
  solidOnActive?: boolean;
  className?: string;
};

export function Icon({
  type,
  size = 'small',
  solid = false,
  solidOnActive = false,
  style,
  className,
  ...rest
}: IconProps & React.SVGProps<SVGSVGElement>) {
  if (!type) return null;

  return (
    <svg
      className={clsx(className, { 'solid-on-active': solidOnActive })}
      width={sizeMappings[size]}
      height={sizeMappings[size]}
      style={style}
      data-testid="icon"
      data-test-type={type}
      role="img"
      {...rest}
    >
      {solidOnActive ? (
        <>
          <use
            data-solid
            style={{ display: !solid ? 'none' : undefined }}
            xlinkHref={`${solidIcons}#${type}-${sizeMappings[size]}`}
            data-testid="use"
          />
          <use
            data-outline
            style={{ display: solid ? 'none' : undefined }}
            xlinkHref={`${outline}#${type}-${sizeMappings[size]}`}
            data-testid="use"
          />
        </>
      ) : (
        <>
          {solid ? (
            <use
              data-solid
              xlinkHref={`${solidIcons}#${type}-${sizeMappings[size]}`}
              data-testid="use"
            />
          ) : (
            <use
              data-outline
              xlinkHref={`${outline}#${type}-${sizeMappings[size]}`}
              data-testid="use"
            />
          )}
        </>
      )}
    </svg>
  );
}

export const sizeMappings: Record<Size, string> = {
  tiny: '16',
  small: '24',
  medium: '32',
  large: '40',
  xlarge: '48',
};

export default Icon;
