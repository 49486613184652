import { capitalizeFirstLetter } from '@kk/shared/utils/strings';
import { Translation } from 'react-i18next';
import lazyWithPreload from 'react-lazy-with-preload';
import { isCalculcationPhase } from '@/api/predicates/casePhase';
import BreadcrumbMatches from '@/components/BreadcrumbMatches';
import type { ExtendedRouteObject } from '@/routes/types';
import loader, { id, type LoaderData, type Params } from './loader';

const CalculationPage = lazyWithPreload(async () => await import('./index'));

export default {
  id,
  path: '/kunde/:companyId/aftale/:contractId/sag/:caseId/beregning',
  loader,
  element: <CalculationPage />,
  handle: ({ data, params }) => {
    const phase = data?.loanCase.phases?.find(isCalculcationPhase);
    return {
      preload: async () =>
        await Promise.all([
          CalculationPage.preload(),
          loader({
            params,
            opts: {
              priority: 'low',
            },
          }),
        ]),
      breadcrumb: {
        tag:
          phase && data?.loanCase ? (
            <BreadcrumbMatches.PhaseStatusTag
              phase={phase}
              loanCase={data.loanCase}
            />
          ) : null,
        label: (
          <span className="flex">
            <Translation>
              {(t) => capitalizeFirstLetter(t('calculation.title'))}
            </Translation>
          </span>
        ),
      },
    };
  },
} as const satisfies ExtendedRouteObject<Params, LoaderData>;
