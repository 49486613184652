import { queryOptions, useQuery } from '@tanstack/react-query';
import ms from 'ms';
import { loanApi } from '@/api';
import { RequestOpts } from '@/api/api-types';
import queryClient from '@/api/queryClient';

export type Response = Awaited<
  ReturnType<typeof loanApi.getCustomerEarliestvalidfilterdate>
>;

export function getEarliestValidFilterDateQuery(opts: RequestOpts = {}) {
  const queryKey = ['earliest-valid-filter-date'];
  return queryOptions({
    queryKey,
    queryFn: ({ signal }) => {
      return loanApi.getCustomerEarliestvalidfilterdate({
        ...opts,
        signal,
      });
    },
    staleTime: ms('1d'),
  });
}

export async function fetchEarliestValidFilterDateQuery(
  opts: RequestOpts = {},
) {
  return queryClient.fetchQuery(getEarliestValidFilterDateQuery(opts));
}

export function useEarliestValidFilterDate(
  options: Omit<ReturnType<typeof getEarliestValidFilterDateQuery>, 'queryKey'>,
) {
  const query = getEarliestValidFilterDateQuery();
  return useQuery({
    ...query,
    ...options,
    initialDataUpdatedAt: queryClient.getQueryState(query.queryKey)
      ?.dataUpdatedAt,
  });
}
