import { useMemo } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { IconType } from '../Icon';
import { Tag } from '../Tag/Tag';

export const statuses = [
  'pending',
  'new',
  'in-progress',
  'waiting',
  'success',
  'fail',
] as const;

export interface StatusTagProps {
  title?: string | null;
  icon?: IconType;
  status?: (typeof statuses)[number];
  solid?: boolean;
  children?: React.ReactNode;
  className?: string;
}

export function StatusTag(props: StatusTagProps) {
  const { t } = useTranslation('ui');
  const title = useMemo<string | null>(() => {
    if (props.title) return props.title;
    return props.status ? t(`status.${props.status}`) : null;
  }, [t, props.title, props.status]);

  const iconProps = useMemo<{ icon?: IconType; solid?: boolean }>(() => {
    switch (props.status) {
      case 'pending':
        return { icon: 'pending' };
      case 'waiting':
        return {
          icon: 'stopwatch',
          solid: true,
          iconClassName: 'text-orange-100',
        };
      case 'success':
        return { icon: 'check-circle', solid: true };
      case 'fail':
        return {
          icon: 'cross-circle',
          solid: true,
          iconClassName: 'text-red-100',
        };
      case 'in-progress':
        return { icon: 'in-progress', solid: true };
      default:
        return {};
    }
  }, [props.status]);

  return (
    <Tag
      {...props}
      {...iconProps}
      className={twMerge(
        clsx({
          'bg-neutral-10': props.status === 'pending',
          'bg-purple-10 text-purple-110': props.status === 'new',
          'bg-blue-10 text-blue-100': props.status === 'in-progress',
          'bg-orange-10': props.status === 'waiting',
          'bg-green-10 text-green-110': props.status === 'success',
          'bg-red-10 text-red-110': props.status === 'fail',
        }),
        props.className,
      )}
    >
      {title}
    </Tag>
  );
}

export default StatusTag;
