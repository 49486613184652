import ui from '@kk/ui/locales/da/ui.json';
import translation from '@/locales/da/translation.json';

export const defaultNS = 'translation';

export const resources = {
  da: {
    translation,
    ui,
  },
} as const;
