import { useQuery } from '@tanstack/react-query';
import ms from 'ms';
import z from 'zod';
import { generateErrorMessage } from 'zod-error';
import { orchestrationApi } from '@/api';
import { RequestOpts } from '@/api/api-types';
import { DEPARTMENTS, ROLES, roles } from '@/api/auth/permissions';
import queryClient from '../queryClient';

export const queryKey = ['config-settings'];

export function getSettingsQuery(opts: RequestOpts = {}) {
  return {
    queryKey,
    queryFn: ({ signal }) => orchestrationApi.getConfig({ ...opts, signal }),
    staleTime: ms('15m'),
    gcTime: Infinity,
    refetchOnReconnect: true,
    refetchInterval: ms('30m'),
    refetchIntervalInBackground: true,
    select: (data: SettingsModel) => {
      const parsedData = typeof data === 'string' ? JSON.parse(data) : data;
      const issues = getSettingsIssues(parsedData);
      if (issues) {
        // eslint-disable-next-line no-console
        console.warn('Invalid settings');
        // eslint-disable-next-line no-console
        console.warn(
          generateErrorMessage(issues, {
            delimiter: { error: '\\n' },
          }),
        );
      }
      return parsedData as SettingsModel;
    },
  };
}

const departmentRoleMapSchema = z
  .record(z.nativeEnum(ROLES), z.array(z.enum(DEPARTMENTS)))
  .refine((obj) => Object.keys(obj).every((key) => roles.includes(key)), {
    message: 'Invalid role name in settings > departmentRoleMap',
  })
  .refine(
    (obj) => {
      const departmentValues = [...new Set(Object.values(obj).flat())];
      return departmentValues.every((department) =>
        DEPARTMENTS.includes(department),
      );
    },
    {
      message: 'Invalid department value in settings > departmentRoleMap',
    },
  );

const settingsSchema = z.object({
  /** The minimum amount for calculations */
  minimumAmount: z.number().min(0),
  /** The maximum amount for calculations */
  maximumAmount: z.number().optional(),
  /** The percentage by which a manually specified compensation amount can deviate
   * from the automatically calculated compensation amount
   * before it necessitates individual price setting involving the financial department. */
  compensationAmountVariancePercentage: z.number().optional(),
  /** Loan product type that are supported in the portals and in the orchestration api */
  loanProducts: z.array(z.string()),
  /** Contracts need periodic recalculations when exceeding a certain duration to ensure their freshness */
  contractUpdateIntervalMinutes: z.number().min(0),
  /** Mapping between roles and departments, used to determine which labels to show in the interface and for permission checks   */
  departmentRoleMap: departmentRoleMapSchema,
  /** The events that are supported in the portals */
  eventDescription: z.array(z.string()), // TODO: Add more specific schema to handle enum
});

export type SettingsModel = z.infer<typeof settingsSchema>;

export function getSettingsIssues(settings: unknown): z.ZodIssue[] | void {
  const result = settingsSchema.safeParse(settings);
  if (!result.success) return result.error.issues;
}

/** This hook fetches the settings used for business logic. */
export function useSettings() {
  return useQuery({
    ...getSettingsQuery(),
    meta: { showErrorToast: true },
    initialDataUpdatedAt: queryClient.getQueryState(queryKey)?.dataUpdatedAt,
  });
}

export default useSettings;
