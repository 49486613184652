import lazyWithPreload from 'react-lazy-with-preload';
import type { ExtendedRouteObject } from '@/routes/types';
import loader, { id, type LoaderData } from './loader';

const Dashboard = lazyWithPreload(async () => await import('./index'));
const CasePage = lazyWithPreload(async () => await import('../CasePage'));

function preloadRelatedRouteComponents() {
  CasePage.preload();
}

export default {
  id,
  path: '/',
  loader: () => {
    window.requestIdleCallback(preloadRelatedRouteComponents);
    return loader();
  },
  element: <Dashboard />,
  handle: () => {
    return {
      preload: async () =>
        await Promise.allSettled([
          Dashboard.preload(),
          loader({
            opts: {
              priority: 'low',
            },
          }),
        ]).then(preloadRelatedRouteComponents),
    };
  },
} as const satisfies ExtendedRouteObject<null, LoaderData>;
