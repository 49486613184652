import z from 'zod';

const configSchema = z.object({
  authAuthority: z.string(),
  authClientId: z.string(),
  authScope: z.string(),
  authRedirect: z.string(),
  subscriptionKey: z.string(),
  apiUrl: z.string(),
  applicationInsightsConnectionString: z.string(),
});

export type Config = z.infer<typeof configSchema>;

export default configSchema;
