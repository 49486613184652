import { useEffect, useRef, useState } from 'react';
import debounce from 'lodash.debounce';

export type DebounceOptions = {
  maxWait?: number;
  leading?: boolean;
  trailing?: boolean;
};
/**
 * A hook that returns a debounced value.
 * @param {T} value The value to be debounced.
 * @param {number} delay The delay in milliseconds before the debounced value is updated.
 * @param {DebounceOptions} [options] The options to configure the debounce behavior.
 * @returns The debounced value.
 */
export function useDebounce<T>(
  value: T,
  delay: number,
  options?: DebounceOptions,
): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  const debounced = useRef(
    debounce(
      (value: T) => {
        setDebouncedValue(value);
      },
      delay,
      options,
    ),
  ).current;

  useEffect(() => {
    debounced(value);
  }, [value, debounced]);

  useEffect(() => {
    return () => {
      debounced.cancel();
    };
  }, [debounced]);

  return debouncedValue;
}

export default useDebounce;
