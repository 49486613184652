/**
 * Splits the input array into smaller arrays of a specified size.
 *
 * @param {Array} arr - The input array to be chunked.
 * @param {number} size - The size of each chunk.
 * @returns {Array} An array of smaller arrays of the specified size.
 */
export function chunks<T>(arr: Array<T>, size: number): Array<T[]> {
  if (size <= 0) return [];

  const output: Array<T[]> = [];
  for (let i = 0; i < arr.length; i += size) {
    output.push(arr.slice(i, i + size));
  }

  return output;
}

/**
 * Returns a new array containing only the unique elements of the input array.
 *
 * @param {Array} arr - The input array to be processed.
 * @returns {Array} A new array containing only the unique elements of the input array.
 */
export function unique<T>(arr: Array<T>): Array<T> {
  const result: Array<T> = [];
  for (let i = 0; i < arr.length; i++) {
    if (result.indexOf(arr.at(i) as T) === -1) {
      result.push(arr.at(i) as T);
    }
  }
  return result;
}

/**
 * Filters an array of objects by a unique value with a given key.
 * @template T
 * @param {T[]} array - The array of objects to filter.
 * @param {keyof T} key - The key to use for filtering unique values.
 * @returns {T[]} - A new array containing objects with unique values for the specified key.
 */
export function filterByUniqueKey<T>(array: T[], key: keyof T): T[] {
  return array.filter(
    (item, index, self) =>
      index === self.findIndex((t) => t[key] === item[key]),
  );
}

/**
 * Interleaves an array with values generated by a function based on the index.
 *
 * @template T - The type of elements in the input array.
 * @param {Array<T>} arr - The array to interleave.
 * @param {(index: number) => unknown} leaveFn - A function that returns a value to interleave with each element.
 * @returns {Array<T>} A new array with interleaved elements.
 *
 * @examplecomponents/calculators/CalculationResultActions/private/NewLoanValidationModal.tso
 * interleave([1, 2, 3], index => index * 10); // Output: [1, 0, 2, 10, 3]
 */
export function interleave<T = unknown>(
  arr: Array<T>,
  leaveFn: (index: number) => unknown,
): Array<T> {
  return arr.flatMap((value, index, array) => {
    if (array.length - 1 !== index) {
      return [value, leaveFn(index)];
    }
    return value;
  }) as Array<T>;
}
