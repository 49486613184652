import { queryOptions, useQuery } from '@tanstack/react-query';
import ms from 'ms';
import { loanApi } from '@/api';
import { RequestOpts } from '@/api/api-types';
import queryClient from '@/api/queryClient';

export type Response = Awaited<
  ReturnType<
    typeof loanApi.getCalculationByCalculationPathHolidaysAndStartDateEndDate
  >
>;

export type GetCalculationHolidaysParams = {
  calculationPath?: string;
  startDate?: string;
  endDate?: string;
};

export function getCalculationHolidaysQuery(
  { calculationPath, startDate, endDate }: GetCalculationHolidaysParams,
  opts: RequestOpts = {},
) {
  const queryKey = [
    'calculation-holidays',
    calculationPath,
    startDate,
    endDate,
  ];
  return queryOptions({
    queryKey,
    queryFn: ({ signal }) => {
      if (!calculationPath) throw new TypeError('No calculationPath provided');
      if (!startDate) throw new TypeError('No startDate provided');
      if (!endDate) throw new TypeError('No endDate provided');
      return loanApi.getCalculationByCalculationPathHolidaysAndStartDateEndDate(
        calculationPath,
        startDate,
        endDate,
        {
          ...opts,
          signal,
        },
      );
    },
    staleTime: ms('1d'),
  });
}

export async function fetchCalculationHolidaysQuery(
  params: GetCalculationHolidaysParams,
  opts: RequestOpts = {},
) {
  return queryClient.fetchQuery(getCalculationHolidaysQuery(params, opts));
}

export function useCalculationHolidays(
  params: GetCalculationHolidaysParams,
  options: Omit<ReturnType<typeof getCalculationHolidaysQuery>, 'queryKey'>,
) {
  const query = getCalculationHolidaysQuery(params);
  return useQuery({
    ...query,
    ...options,
    initialDataUpdatedAt: queryClient.getQueryState(query.queryKey)
      ?.dataUpdatedAt,
  });
}
