import { useEffect, useRef } from 'react';
import Spinner from '@kk/ui/components/Spinner';
import ms from 'ms';

export const PageSpinner = ({ immediate }: { immediate?: boolean }) => {
  const spinnerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (immediate) return;
    const timeout = setTimeout(() => {
      if (spinnerRef.current) {
        spinnerRef.current.style.opacity = '1';
      }
    }, ms('1s'));

    return () => clearTimeout(timeout); // Cleanup timeout if component unmounts before timeout completes
  }, [immediate]);

  const spinnerContainerStyle = {
    opacity: immediate ? 1 : 0, // Initial opacity set to 0 for fade-in effect
    transition: 'opacity 0.5s', // Transition property for fade-in animation
  };

  return (
    <div className="text-blue-60 fixed top-0 flex h-screen w-screen items-center justify-center">
      <div ref={spinnerRef} style={spinnerContainerStyle}>
        <Spinner size="large" />
      </div>
    </div>
  );
};

export default PageSpinner;
