import { useEffect } from 'react';
import ms from 'ms';
import NProgress from 'nprogress';
import { useSnapshot } from 'valtio';
import { HeaderSkeleton } from '@/components/Header/Header';
import { PageSpinner } from '@/routes/PageSpinner';
import state from '@/state';

function Fallback() {
  const { hasCache } = useSnapshot(state);
  useEffect(() => {
    let timeout: number | undefined = window.setTimeout(
      () =>
        window.requestAnimationFrame(() => {
          if (NProgress.isStarted()) return;
          NProgress.start();
          timeout = undefined;
        }),
      hasCache ? ms('1s') : 0,
    );

    return () => {
      if (timeout) {
        window.clearTimeout(timeout);
      } else {
        if (NProgress.status !== null) {
          NProgress.done(true);
        }
      }
    };
  }, [hasCache]);

  return (
    <>
      <HeaderSkeleton />
      <main
        role="main"
        className="relative pt-14"
        data-testid="router-fallback"
      >
        <PageSpinner immediate={hasCache === false} />
      </main>
    </>
  );
}
export default Fallback;
