import { z } from 'zod';

export const flagSchema = z.object({
  /**
   * Enables the top navigation bar.
   * @feature Feature 32682
   * @see {@link https://dev.azure.com/kommunekredit/KK%20Laaneportal/\_workitems/edit/32628}
   */
  enableTopNavigation: z.boolean(),
  /**
   * Enables notifications.
   * @feature Feature 27259
   * @see {@link https://dev.azure.com/kommunekredit/KK%20Laaneportal/\_workitems/edit/27259}
   */
  enableNotifications: z.boolean(),
});

export type Flags = z.infer<typeof flagSchema>;

const config: Flags = {
  enableTopNavigation: true,
  enableNotifications: false,
};

const featureFlags = flagSchema.parse(config);

export default featureFlags;
