import {
  keepPreviousData,
  QueryObserverOptions,
  useQuery,
} from '@tanstack/react-query';
import ms from 'ms';
import { searchApi } from '@/api';
import { RequestOpts } from '@/api/api-types';
import queryClient from '@/api/queryClient';

export const MIN_SEARCH_QUERY_LENGTH = 3;

export const queryKey = 'search';
const method = searchApi.search;
export type Response = Awaited<ReturnType<typeof searchApi.search>>;
export type GetSearchResultsQueryParams = Parameters<
  typeof searchApi.search
>[0];

export function getSearchResultsQuery(
  params: GetSearchResultsQueryParams,
  opts: RequestOpts = {},
) {
  const queryKey = ['search', params];
  return {
    queryKey,
    queryFn: ({ signal }) => method({ ...params }, { ...opts, signal }),
    initialDataUpdatedAt: queryClient.getQueryState(queryKey)?.dataUpdatedAt,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    placeholderData:
      !!params?.query && params.query.length >= MIN_SEARCH_QUERY_LENGTH
        ? keepPreviousData
        : undefined,
    staleTime: ms('15m'),
    gcTime: ms('30s'),
  };
}

export async function fetchSearchResultsQuery(
  queryParams: GetSearchResultsQueryParams,
  opts: RequestOpts = {},
) {
  return queryClient.fetchQuery(getSearchResultsQuery(queryParams, opts));
}

export function useSearchResults(
  queryParams: GetSearchResultsQueryParams,
  options?: Omit<QueryObserverOptions<Response>, 'queryKey'>,
) {
  const query = getSearchResultsQuery(queryParams);
  return useQuery({
    ...query,
    ...options,
  });
}
