import {
  keepPreviousData,
  queryOptions,
  useQuery,
} from '@tanstack/react-query';
import compareDesc from 'date-fns/compareDesc';
import ms from 'ms';
import { caseApi } from '@/api';
import { RequestOpts } from '@/api/api-types';
import { useHasPermission } from '@/api/auth/claims';
import { CASE_SCOPES } from '@/api/auth/permissions';
import queryClient from '@/api/queryClient';

export type Response = Awaited<ReturnType<typeof caseApi.getCasesActivelite>>;

export function getAllActiveCasesLiteQuery(opts: RequestOpts = {}) {
  const queryKey = [CASE_SCOPES.getActiveCasesLite];
  return queryOptions({
    queryKey,
    queryFn: async ({ signal }) =>
      caseApi.getCasesActivelite({
        ...opts,
        signal,
      }),
    meta: {
      showErrorToast: true,
    },
    retry: 1,
    retryDelay: 0,
    staleTime: ms('3m'),
    gcTime: ms('5m'),
    refetchInterval: ms('1m'),
    refetchOnWindowFocus: true,
    placeholderData: keepPreviousData,
    select: (data) =>
      data.sort((a, b) =>
        compareDesc(new Date(a.createDate ?? 0), new Date(b.createDate ?? 0)),
      ),
  });
}

export async function fetchAllActiveCasesLiteQuery(init: RequestOpts = {}) {
  return queryClient.fetchQuery(getAllActiveCasesLiteQuery(init));
}

export function useAllActiveCasesLite(
  options?: Omit<ReturnType<typeof getAllActiveCasesLiteQuery>, 'queryKey'>,
) {
  const canGetAllActiveCases = useHasPermission(CASE_SCOPES.getActiveCasesLite);
  const query = getAllActiveCasesLiteQuery();
  return useQuery({
    ...query,
    retry: 1,
    ...options,
    initialDataUpdatedAt: queryClient.getQueryState(query.queryKey)
      ?.dataUpdatedAt,
    enabled: canGetAllActiveCases,
  });
}

export default useAllActiveCasesLite;
