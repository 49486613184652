/**
 * Handles settled promises and returns an array of resolved values or `Error` instances.
 * @template T - Tuple of promises.
 * @param {T} promises - Array of promises.
 * @returns {Promise<{
 *   [K in keyof T]: T[K] extends Promise<infer R> ? R | Error : never;
 * }>} - A promise that resolves to an array where each element represents a resolved value or `Error` instance.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function handleSettledPromises<T extends Promise<any>[]>(
  promises: [...T],
): Promise<{
  [K in keyof T]: T[K] extends Promise<infer R> ? R | Error : never;
}> {
  const statuses = await Promise.allSettled(promises);
  return statuses.map((settled) => {
    if (settled.status === 'fulfilled') {
      return settled.value as T[number] extends Promise<infer R> ? R : never;
    }
    return settled.reason as Error;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) as any;
}
