import { queryOptions, useQuery } from '@tanstack/react-query';
import ms from 'ms';
import { companyApi } from '@/api';
import { RequestOpts } from '@/api/api-types';
import { COMPANY_SCOPES } from '@/api/auth/permissions';
import queryClient from '@/api/queryClient';

export type Response = Awaited<ReturnType<typeof companyApi.getCompanyById>>;

export type GetCompanyQueryParams = {
  companyId?: string | null;
};

export function getCompanyQuery(
  params: GetCompanyQueryParams,
  opts: RequestOpts = {},
) {
  const queryKey = [COMPANY_SCOPES.getCompany, params.companyId];
  return queryOptions({
    queryKey,
    queryFn: ({ signal }) => {
      if (!params.companyId) throw new TypeError('No companyId provided');
      return companyApi.getCompanyById(params.companyId, { ...opts, signal });
    },
    staleTime: ms('1d'),
  });
}

export async function fetchCompanyQuery(
  params: GetCompanyQueryParams,
  opts: RequestOpts = {},
) {
  return queryClient.fetchQuery(getCompanyQuery(params, opts));
}

export function useCompany(
  params: GetCompanyQueryParams,
  options: Omit<ReturnType<typeof getCompanyQuery>, 'queryKey'>,
) {
  const query = getCompanyQuery(params);
  return useQuery({
    ...query,
    ...options,
    initialDataUpdatedAt: queryClient.getQueryState(query.queryKey)
      ?.dataUpdatedAt,
  });
}
