import {
  QueryObserverOptions,
  queryOptions,
  useQuery,
} from '@tanstack/react-query';
import ms from 'ms';
import { caseApi } from '@/api';
import { RequestOpts } from '@/api/api-types';
import { DOCUMENT_SCOPES } from '@/api/auth/permissions';
import queryClient from '@/api/queryClient';

export type Response = Blob | ArrayBuffer;

export type GetDocumentQueryParams = {
  recordId?: string | null;
};

export type GetDocumentQueryOptions = QueryObserverOptions<Response>;

export function getDocumentQuery(
  params?: GetDocumentQueryParams,
  opts: RequestOpts = {},
) {
  const queryKey = [DOCUMENT_SCOPES.getDocument, params?.recordId].filter(
    Boolean,
  );
  return queryOptions({
    queryKey,
    queryFn: ({ signal }) => {
      if (!params?.recordId) throw new TypeError('No recordId provided');
      return caseApi.getDocumentByRecordIdPdf(params.recordId, {
        ...opts,
        headers: {
          responseType: 'application/text',
          ContentType: 'application/text',
        },
        signal,
      }) as Promise<Response>;
    },
    staleTime: ms('7d'),
    gcTime: ms('30s'),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
}

export function fetchDocumentByRecordIdQuery(
  params: GetDocumentQueryParams,
  opts: RequestOpts = {},
) {
  return queryClient.fetchQuery(getDocumentQuery(params, opts));
}

export default function useDocumentByRecordId(
  params: GetDocumentQueryParams,
  options?: Omit<ReturnType<typeof getDocumentQuery>, 'queryKey'>,
) {
  const query = getDocumentQuery(params);
  return useQuery({
    ...query,
    ...options,
    initialDataUpdatedAt: queryClient.getQueryState(query.queryKey)
      ?.dataUpdatedAt,
  });
}
