import lazyWithPreload from 'react-lazy-with-preload';
import { loanApi } from '@/api';
import type { ExtendedRouteObject } from '@/routes/types';
import loader, { id, type LoaderData, type Params } from './loader';

const CustomerPage = lazyWithPreload(async () => await import('./index'));
const CustomerPageTabs = lazyWithPreload(
  async () => await import('./private/CustomerPageTabs'),
);
const CustomerCases = lazyWithPreload(
  async () => await import('./private/CustomerCases'),
);

export default {
  id,
  path: '/kunde/:companyId',
  loader,
  element: <CustomerPage />,
  children: [
    {
      id: 'owner',
      path: '',
      element: <CustomerPageTabs relation={loanApi.Relation.Owner} />,
    },
    {
      id: 'guarantee',
      path: 'garanti',
      element: <CustomerPageTabs relation={loanApi.Relation.Guarantee} />,
    },
    {
      id: 'partnershipLiability',
      path: 'interresantskaber',
      element: (
        <CustomerPageTabs relation={loanApi.Relation.PartnershipLiability} />
      ),
    },
    {
      id: 'customerCases',
      path: 'sager',
      element: <CustomerCases />,
    },
  ],
  handle: ({ request, params, id, data }) => {
    return {
      preload: async () =>
        Promise.all([
          CustomerPage.preload(),
          id === 'customerCases'
            ? CustomerCases.preload()
            : CustomerPageTabs.preload(),
          loader({ params, request }),
        ]),
      breadcrumb: {
        label: data?.company.legalName ? (
          <span className="flex">{data.company.legalName}</span>
        ) : null,
      },
    };
  },
} as const satisfies ExtendedRouteObject<Params, LoaderData>;
