import { queryOptions, useQuery } from '@tanstack/react-query';
import ms from 'ms';
import { loanApi } from '@/api';
import { RequestOpts } from '@/api/api-types';
import { useHasPermission } from '@/api/auth/claims';
import { LOAN_SCOPES } from '@/api/auth/permissions';
import queryClient from '@/api/queryClient';

export type Response = loanApi.LoanInfoResponse;

export type GetLoanQueryParams = {
  contractId?: string | null;
  loanNo?: number;
};

export function getLoanQuery(
  params?: GetLoanQueryParams,
  opts: RequestOpts = {},
) {
  const queryKey = [
    LOAN_SCOPES.getLoanInfo,
    params?.contractId,
    params?.loanNo,
  ].filter(Boolean);
  return queryOptions({
    queryKey,
    queryFn: ({ signal }) => {
      if (!params?.contractId) throw new TypeError('No contractId provided');

      return loanApi.getLoaninfoByContractId(
        params.contractId,
        { loanNo: params.loanNo },
        { ...opts, signal },
      ) as unknown as Promise<Response>;
    },
    staleTime: ms('1d'),
    gcTime: ms('15m'),
    initialDataUpdatedAt: queryClient.getQueryState(queryKey)?.dataUpdatedAt,
  });
}

export async function fetchLoanQuery(
  queryParams: GetLoanQueryParams,
  opts: RequestOpts = {},
) {
  return queryClient.fetchQuery(getLoanQuery(queryParams, opts));
}

export function useLoan(
  params: GetLoanQueryParams,
  options: Omit<ReturnType<typeof getLoanQuery>, 'queryKey'> = {},
) {
  const canGetLoanInfo = useHasPermission(LOAN_SCOPES.getLoanInfo);
  const query = getLoanQuery(params);
  return useQuery({
    ...query,
    staleTime: 0,
    ...options,
    enabled: !!params.contractId && canGetLoanInfo,
  });
}
