import { Configuration, LogLevel } from '@azure/msal-browser';
import { ClientConfig } from '@/client-config';

export const msalConfig: Configuration = {
  auth: {
    clientId: ClientConfig.authClientId,
    authority: ClientConfig.authAuthority,
    redirectUri: ClientConfig.authRedirect,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      logLevel:
        // only log warnings in development, verbose in production but only if devtools are enabled
        import.meta.env.MODE === 'development'
          ? LogLevel.Warning
          : LogLevel.Verbose,
      loggerCallback: (level, message, containsPii) => {
        // HACK: for some reason using the @/state module directly here causes vitest to fail all tests
        if (!globalThis.devtoolsEnabled) return;
        if (import.meta.env.MODE === 'test') return;
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            // eslint-disable-next-line no-console
            console.info(message);
            return;
          case LogLevel.Verbose:
            // eslint-disable-next-line no-console
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [ClientConfig.authScope],
  tokenRefreshUri: import.meta.env.DEV
    ? `http://localhost:${import.meta.env.PORT ?? 3000}`
    : window.location.origin + '/auth.html',
};
