import { allPaths, type PathWithPlaceholders as PathValue } from '@/routes';

type PathParamsToPath<PathValue, Params> =
  PathValue extends `${infer Prefix}:${infer Param}/${infer Suffix}`
    ? Param extends keyof Params
      ? Params[Param] extends string | number
        ? `${Prefix}${Params[Param]}${PathParamsToPath<`/${Suffix}`, Params>}`
        : never
      : never
    : PathValue extends `${infer Prefix}:${infer Param}`
      ? Param extends keyof Params
        ? Params[Param] extends string | number
          ? `${Prefix}${Params[Param]}`
          : never
        : never
      : PathValue;

export type PathParams<Path extends string> =
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Path extends `${infer _}:${infer Param}/${infer Suffix}`
    ? {
        [K in Param | keyof PathParams<`/${Suffix}`>]:
          | string
          | number
          | null
          | undefined;
      }
    : // eslint-disable-next-line @typescript-eslint/no-unused-vars
      Path extends `${infer _}:${infer Param}`
      ? { [K in Param]: string | null | undefined }
      : {};

type SearchParams =
  | Record<string, string | boolean | number | null | undefined>
  | undefined;

type QueryStringParamsToString<Params extends SearchParams> = {
  [K in keyof Params]: `?${string}=${string}`;
}[keyof Params];

export type GenerateURL<
  Path extends PathValue,
  Params extends PathParams<Path>,
  Search extends SearchParams = {},
> = Search extends {}
  ? `${PathParamsToPath<Path, Params>}${string extends keyof Search
      ? ''
      : '?'}${QueryStringParamsToString<Search>}`
  : PathParamsToPath<Path, Params>;

/**
 * Generates a URL based on the provided path, parameters, and search parameters.
 *
 * @template Path - The value representing the path.
 * @template Params - The type representing the path parameters.
 * @template Search - The type representing the search parameters.
 * @param {Path} path - The path value.
 * @param {Params | null | undefined} params - The path parameters.
 * @param {Search} [search] - The search parameters.
 * @throws {Error} When an invalid path, parameter, or value is provided.
 *
 * @description
 * Generates a URL by replacing placeholders in the provided path with values from the `params` object. The search parameters are appended as a query string. An error is thrown if the path or parameter is invalid.
 */
export function generateUrl<Path extends PathValue>(
  path: Path,
  params: PathParams<Path> | null | undefined,
  search?: SearchParams,
) {
  // Check if the path is valid and that it exists in the collection of routes
  if (!allPaths.includes(path)) {
    throw new Error(`Invalid path "${path}".`);
  }

  const pathPlaceholders = path.match(/:[a-zA-Z]+/g);
  const paramKeys = Object.keys(params ?? {});
  const hasPlaceholders =
    pathPlaceholders?.length !== undefined && pathPlaceholders.length !== 0;

  if (
    params &&
    hasPlaceholders &&
    pathPlaceholders.length !== paramKeys.length
  ) {
    throw new Error(
      'Mismatch between number of parameters and placeholders in the path.',
    );
  }

  let url = path as string;
  // Replace path placeholders with actual parameter values
  if (params) {
    for (const key of paramKeys) {
      // Check if the parameter key exists in the path
      if (!path.includes(`:${key}`)) {
        throw new Error(
          `Invalid parameter key "${key}". It does not exist in the path.`,
        );
      }
      url = url.replace(`:${key}`, params[key] as string);
    }
  }

  // Append search parameters to the URL
  if (search) {
    const queryString = Object.entries(search)
      .filter(([, value]) => value !== null && value !== undefined)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(
            value ? value.toString() : '',
          )}`,
      )
      .join('&');
    url = `${url}?${queryString}`;
  }
  return url as GenerateURL<Path, PathParams<Path>, SearchParams>;
}
