import { handleSettledPromises } from '@kk/shared/utils/async';
import { useParams, useRouteLoaderData } from 'react-router';
import { RequestOpts } from '@/api/api-types';
import { assertNoLoaderErrors } from '@/api/errors';
import { fetchAllActiveCasesLiteQuery } from '@/api/hooks/case/useAllActiveCasesLite';
import { fetchRecentlyViewedCasesQuery } from '@/api/hooks/case/useRecentlyViewedCases';

export type LoaderData = Awaited<ReturnType<typeof loader>>;

export const id = 'dashboard' as const;

export type Params = {};

async function loader({
  opts = {},
}: { params?: Params; opts?: RequestOpts } = {}) {
  const data = await handleSettledPromises([
    fetchAllActiveCasesLiteQuery(opts),
    fetchRecentlyViewedCasesQuery(opts),
  ]);
  assertNoLoaderErrors(id, data);
  const [allActiveCases, recentlyViewedCases] = data;

  return {
    recentlyViewedCases,
    allActiveCases,
  };
}

export const useDashboardPageLoaderData = () => useRouteLoaderData(id);

export const useDashboardPageParams = () => useParams<Params>();

export default loader;
