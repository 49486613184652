import { queryOptions, useQuery } from '@tanstack/react-query';
import ms from 'ms';
import { caseApi } from '@/api';
import { RequestOpts } from '@/api/api-types';
import { CASE_SCOPES } from '@/api/auth/permissions';
import { isAnyPhaseWithExternalDependencyInProgress } from '@/api/predicates/casePhase';
import queryClient from '@/api/queryClient';

export type Response = Awaited<
  ReturnType<typeof caseApi.getCaseByCompanyIdCaseslite>
>;

export type GetCompanyCasesQueryParams = {
  companyId?: string | null;
  queryFilter?: caseApi.QueryFilter;
};

export function getCompanyCasesQuery(
  params?: GetCompanyCasesQueryParams,
  opts: RequestOpts = {},
) {
  const queryKey = [
    CASE_SCOPES.getCompanyCases,
    params?.companyId,
    params?.queryFilter ?? caseApi.QueryFilter.All,
  ];
  return queryOptions({
    queryKey,
    queryFn: ({ signal }) => {
      if (!params?.companyId) throw new TypeError('No companyId provided');
      return caseApi.getCaseByCompanyIdCaseslite(
        params.companyId,
        { queryFilter: params.queryFilter ?? caseApi.QueryFilter.All },
        {
          ...opts,
          signal,
        },
      );
    },
    refetchInterval: (query) => {
      const anyPhasesWithExternalDependencyInProgress = query.state.data?.some(
        (loanCase) => {
          return isAnyPhaseWithExternalDependencyInProgress(loanCase.phases);
        },
      );
      if (anyPhasesWithExternalDependencyInProgress) {
        return ms('30s');
      }
      return false;
    },
    staleTime: ms('3m'),
    gcTime: ms('5m'),
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: true,
  });
}

export async function fetchCompanyCasesQuery(
  params?: GetCompanyCasesQueryParams,
  opts: RequestOpts = {},
) {
  return queryClient.fetchQuery(getCompanyCasesQuery(params, opts));
}

export function useCompanyCases(
  params?: GetCompanyCasesQueryParams,
  options?: Omit<ReturnType<typeof getCompanyCasesQuery>, 'queryKey'>,
) {
  const query = getCompanyCasesQuery(params);
  return useQuery({
    ...query,
    staleTime: 0,
    ...options,
    initialDataUpdatedAt: queryClient.getQueryState(query.queryKey)
      ?.dataUpdatedAt,
  });
}
