import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import {
  ApplicationInsights,
  BaseTelemetryPlugin,
} from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';
import { ClientConfig } from '@/client-config';

const browserHistory = createBrowserHistory();
const appInsightsPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: ClientConfig.applicationInsightsConnectionString,
    extensions: [appInsightsPlugin satisfies BaseTelemetryPlugin],
    extensionConfig: {
      [appInsightsPlugin.identifier]: { history: browserHistory },
    },
    // disabled to prevent back/forward cache from being blocked
    disablePageUnloadEvents: ['unload', 'beforeunload'],
  },
});
if (!import.meta.env.DEV) {
  appInsights.loadAppInsights();
  appInsights.trackPageView();
}
export { appInsightsPlugin, appInsights };
