import lazyWithPreload from 'react-lazy-with-preload';
import type { ExtendedRouteObject } from '@/routes/types';
import loader, { id, type LoaderData, type Params } from './loader';

const CasesOverviewPage = lazyWithPreload(async () => await import('./index'));
const CasePage = lazyWithPreload(async () => await import('../CasePage'));
const LoanPage = lazyWithPreload(async () => await import('../LoanPage'));

function preloadRelatedRouteComponents() {
  LoanPage.preload();
  CasePage.preload();
}

export default {
  id,
  path: '/kunde/:companyId/aftale/:contractId/sager',
  loader: (args) => {
    window.requestIdleCallback(preloadRelatedRouteComponents);
    return loader(args);
  },
  element: <CasesOverviewPage />,
  handle: ({ params }) => {
    return {
      preload: async () =>
        await Promise.allSettled([
          CasesOverviewPage.preload(),
          loader({ params, opts: { priority: 'low' } }),
        ]).then(preloadRelatedRouteComponents),
    };
  },
} as const satisfies ExtendedRouteObject<Params, LoaderData>;
