import { useMemo } from 'react';
import useLocalStorage from '@kk/shared/hooks/useLocalStorage';
import { AutocompleteSearchResult, SearchTypes } from './structureData';

const ITEM_LIMIT = 9;
const buster = import.meta.env.DEV ? 'dev' : __BUILD_ID__;

export function useSavedSearch() {
  const [savedSearch, setSavedSearch] = useLocalStorage<{
    companies?: AutocompleteSearchResult[];
    loans?: AutocompleteSearchResult[];
    cases?: AutocompleteSearchResult[];
  }>(`recent-searches-${buster}`, {
    companies: [],
    loans: [],
    cases: [],
  });

  function addSavedSearch(result: AutocompleteSearchResult) {
    if (result.searchType !== 'entity') return;
    try {
      if (savedSearch[result.group]?.some((item) => item.key === result.key)) {
        const newSavedSearch = savedSearch[result.group]?.filter(
          (item) => item.key !== result.key,
        );

        newSavedSearch?.unshift(result);
        setSavedSearch({
          ...savedSearch,
          [result.group]: newSavedSearch,
        });
      } else {
        const newSavedSearch = [...(savedSearch[result.group] ?? [])];
        if (newSavedSearch[result.group]?.length >= ITEM_LIMIT) {
          newSavedSearch.pop();
        }
        setSavedSearch({
          ...savedSearch,
          [result.group]: [result, ...newSavedSearch],
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  const grouped = useMemo(() => {
    let groupedSavedSearchArray: AutocompleteSearchResult[] = [];

    try {
      Object.keys(savedSearch).forEach((key) => {
        if (savedSearch[key]?.length) {
          groupedSavedSearchArray.push({
            name: key,
            searchType: SearchTypes.Group,
            count: savedSearch[key]?.length,
            key,
          });
          groupedSavedSearchArray = groupedSavedSearchArray.concat(
            savedSearch[key]?.slice(0, key === 'loans' ? 9 : 5),
          );
        }
      });
    } catch (e) {
      console.error(e);
    }

    return groupedSavedSearchArray;
  }, [savedSearch]);

  return { savedSearch: grouped, addSavedSearch };
}
