/**
 * This module contains predicate functions related to case calculations.
 * Predicates are used to determine specific properties or conditions of case calculations.
 */

import { caseApi, loanApi } from '@/api';

export function isOriginalCalculation(
  calculation: caseApi.CaseCalculationModel | null | undefined,
): boolean {
  return Boolean(calculation?.displayId === 0);
}

/**
 * Determines if a case calculation is an agreement candidate.
 *
 * @param calculation - The case calculation object to be evaluated.
 * @returns A boolean indicating whether the calculation is an agreement candidate.
 */
export function isAgreementCandidate(
  calculation: caseApi.CaseCalculationModel | null | undefined,
): boolean {
  return Boolean(
    !!calculation?.isAgreementCandidateCode && calculation.isActive,
  );
}

/**
 * Checks if a calculation is active.
 *
 * @param calculation - The calculation model.
 * @returns A boolean value indicating if the calculation is active.
 */
export function isCalculationActive(
  calculation: caseApi.CaseCalculationModel | null | undefined,
): boolean {
  return Boolean(calculation?.isActive) || calculation?.displayId === 0;
}

/**
 * Checks if individual price setting is required.
 * This is when finance has to confirm the compensationAmount of a case calculation.
 *
 * @param params - The parameters for determining individual price setting requirement.
 * @param params.loanProduct - The type of loan product.
 * @param params.maximumAmount - The maximum amount for the loan.
 * @param params.compensationAmount - The compensation amount for the loan.
 * @returns A boolean value indicating if individual price setting is required.
 */
export function isIndividualPriceSettingRequired({
  loanProduct,
  maximumAmount,
  amount,
}: {
  loanProduct: loanApi.ContractLoanTypeEnum | null | undefined;
  maximumAmount: number | null | undefined;
  amount: number | null | undefined;
}) {
  return Boolean(
    loanProduct?.toLowerCase() ===
      loanApi.ContractLoanTypeEnum.KKfast.toLowerCase() &&
      amount &&
      maximumAmount &&
      amount >= maximumAmount,
  );
}
