import { QueryCache, QueryClient } from '@tanstack/react-query';
import { experimental_createPersister } from '@tanstack/react-query-persist-client';
import ms from 'ms';
import { isErrorResponse } from '@/api/api-types';
import { handleErrorNotification, handleTokenError } from '@/api/errors';
import { CASE_SCOPES, COMPANY_SCOPES, LOAN_SCOPES } from './auth/permissions';
import { getDocumentQuery } from './hooks/case/useDocumentByRecordId';
import { getSettingsQuery } from './hooks/useSettings';
import storage from './storage';

// Create a set of query keys that need to be persisted
const queryMatches = new Set<string>([
  ...getSettingsQuery().queryKey,
  ...getDocumentQuery().queryKey,
  ...Object.values(CASE_SCOPES).filter((value) => value.startsWith('get')),
  ...Object.values(COMPANY_SCOPES).filter((value) => value.startsWith('get')),
  ...Object.values(LOAN_SCOPES).filter((value) => value.startsWith('get')),
]);

// Set the prefix and cache buster for the persisted queries
export const prefix = 'react-query:';
const buster = import.meta.env.DEV ? 'dev' : __BUILD_ID__;

export const persister = experimental_createPersister({
  prefix,
  buster,
  storage,
  maxAge: ms('5d'),
  filters: {
    predicate: (query) => {
      return query.queryKey.some((key: string) => queryMatches.has(key));
    },
  },
  // Serialization and deserialization isn't needed for indexedDB
  // https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API/Basic_Terminology#key_characteristics
  serialize: (data) => data,
  deserialize: (data) => data,
});

export const queryCache = new QueryCache({
  onError: (error, query) => {
    if (isErrorResponse(error)) {
      if (query.meta?.showErrorToast) {
        // shows after retries have been exhausted
        handleErrorNotification(error, query.queryKey.join(''));
      }
      if (query.queryKey.includes('decode-access-token')) {
        handleTokenError(error);
      }
    }
  },
});

const queryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    queries: {
      structuralSharing: true,
      retry: import.meta.env.MODE === 'production' ? 3 : false,
      retryDelay: 5000,
      gcTime: ms('2m'),
      staleTime: ms('5m'),
      persister:
        import.meta.env.MODE !== 'test' && 'indexedDB' in globalThis
          ? persister
          : undefined,
    },
    mutations: {
      gcTime: 0,
    },
  },
});

export default queryClient;
