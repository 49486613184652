import { isObject } from '@kk/shared/utils/type-guards';

export interface IError {
  Message?: string;
  StatusCode?: number;
  ExceptionMessage?: string;
  InnerException?: string;
}

export type RequestOpts = Omit<RequestInit, 'body' | 'headers'> & {
  priority?: 'high' | 'low';
};

/**
 * Checks if the given object is an instance of `IError` by examining the presence of specific properties.
 *
 * @param {unknown} obj - The object to be checked.
 * @returns {boolean} - Indicates whether the object is an instance of `IError`.
 */
export function isError(obj: unknown): obj is IError {
  return (
    isObject(obj) &&
    ['StatusCode', 'Message', 'ExceptionMessage', 'InnerException'].every(
      (prop) => prop in obj,
    )
  );
}

export type ErrorResponse<TData = { message?: string }> = Response & {
  data?: TData;
};

export function isErrorResponse<TData = { message?: string }>(
  response: unknown,
): response is ErrorResponse<TData> {
  return (
    isObject(response) &&
    'status' in response &&
    'headers' in response &&
    'text' in response
  );
}
