import React from 'react';
import { setTheme } from '@kk/ui/hooks/useTheme';
import i18n, { initI18n } from '@kk/ui/i18n';
import ReactDOM from 'react-dom/client';
import msalInstance from '@/api/auth/msalInstance';
import App from '@/App';
import { resources } from '@/locales/resources';
import { resolveQueryCacheStatus } from '@/state';
import './styles/globals.css';
import { handleTokenError } from './api/errors';

msalInstance
  .initialize()
  .then(async () => {
    Promise.all([
      msalInstance.handleRedirectPromise(),
      resolveQueryCacheStatus(),
    ]).then(async ([tokenResponse]) => {
      if (tokenResponse) {
        msalInstance.setActiveAccount(tokenResponse.account);
      }
      if (import.meta.env.DEV) {
        await developmentSetup();
      }
      // start the application after authentication check
      return main();
    });
  })
  .catch((e) => {
    handleTokenError(e);
  });

async function main() {
  setTheme('laane');

  if (!i18n.isInitialized) {
    initI18n({
      lng: 'da',
      resources,
    });
  }

  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
}

async function developmentSetup() {
  const [{ mockResult }, { clearCache }] = await Promise.all([
    import('@/api/mocks/mockData/auth/test-constants'),
    import('@/state'),
  ]);
  msalInstance.acquireTokenSilent = async () => mockResult;
  const socket = new WebSocket('ws://localhost:9100');
  socket.addEventListener('message', ({ data }) => {
    const { type, event } = JSON.parse(data) as {
      type: string;
      event: string;
    };
    if (type !== 'custom') return;
    switch (event) {
      case 'factory-changed': // clear cache on factory changes
        // eslint-disable-next-line no-console
        console.log('🏭 database factory changed: clearing browser cache');
        clearCache();
        localStorage.removeItem('recently-viewed-cases');
        break;
      case 'factory-reset': // clear cache on factory changes
        // eslint-disable-next-line no-console
        console.log('🏭 database factory reset: reloading page');
        window.requestIdleCallback(() => window.location.reload());
        break;
    }
  });
}
