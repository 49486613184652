import { BaseAvatar } from '@kk/ui/components/BaseAvatar';
import { BaseAvatarProps } from '@kk/ui/components/BaseAvatar/BaseAvatar';
import { useDecodedAccessToken } from '@/api/auth/claims';

export type AvatarProps = Omit<BaseAvatarProps, 'activeUserId' | 'userId'> & {
  /** external user id */
  crmGuid?: string | null | undefined;
  /** only exists for internal users, can be compared to the current user's OnPremSamAccountName */
  samAccountName?: string | null | undefined;
};

function Avatar({
  crmGuid,
  samAccountName,
  isCurrentUser,
  ...rest
}: AvatarProps) {
  const { OnPremSamAccountName } = useDecodedAccessToken() ?? {};
  const isExternalUser = !!crmGuid && !samAccountName;
  const samAccountNamesAreEqual =
    samAccountName?.toUpperCase() === OnPremSamAccountName?.toUpperCase();
  return (
    <BaseAvatar
      {...rest}
      isExternalUser={isExternalUser}
      isCurrentUser={isCurrentUser ?? samAccountNamesAreEqual}
    />
  );
}

export default Avatar;
