import { queryOptions, useQuery } from '@tanstack/react-query';
import { loanApi } from '@/api';
import { RequestOpts } from '@/api/api-types';
import { useHasPermission } from '@/api/auth/claims';
import { LOAN_SCOPES } from '@/api/auth/permissions';
import queryClient from '@/api/queryClient';

export type Response = Awaited<
  ReturnType<typeof loanApi.getCustomerByCompanyIdEodloanportfolio>
>;

export type GetLoanPortfolioQueryParams = {
  companyId?: string | null;
  date?: string | null;
};

export function getLoanPortfolioQuery(
  params?: GetLoanPortfolioQueryParams,
  opts: RequestOpts = {},
) {
  const queryKey = [
    LOAN_SCOPES.getLoanPortfolio,
    params?.companyId,
    params?.date,
  ];

  return queryOptions({
    queryKey,
    queryFn: ({ signal }) => {
      if (!params?.companyId) throw new TypeError('No companyId provided');
      return loanApi.getCustomerByCompanyIdEodloanportfolio(
        params.companyId,
        { date: params.date ?? undefined },
        { ...opts, signal },
      );
    },
  });
}
export async function fetchLoanPortfolioQuery(
  params?: GetLoanPortfolioQueryParams,
  opts: RequestOpts = {},
) {
  return queryClient.fetchQuery(getLoanPortfolioQuery(params, opts));
}

export function useLoanPortfolio(
  params: GetLoanPortfolioQueryParams,
  options: Omit<ReturnType<typeof getLoanPortfolioQuery>, 'queryKey'> = {},
) {
  const canGetLoanPortfolio = useHasPermission(LOAN_SCOPES.getLoanPortfolio);
  const query = getLoanPortfolioQuery(params);
  return useQuery({
    ...query,
    ...options,
    enabled: !!params.companyId && canGetLoanPortfolio,
    initialDataUpdatedAt: queryClient.getQueryState(query.queryKey)
      ?.dataUpdatedAt,
  });
}
