/**
 * Validates the presence of parameters in an object or throws an error if any parameters are missing.
 *
 * @param {Record<string, any>} object - The object containing the parameters to validate.
 * @throws {Error} If any parameters are missing (undefined or null).
 */
export function assertParamsPrescence(
  object: Record<string, unknown>,
): asserts object {
  const missingKeys: string[] = [];

  for (const [key, value] of Object.entries(object)) {
    if (
      value === '' ||
      value === 'undefined' ||
      value === undefined ||
      value === null
    ) {
      missingKeys.push(key);
    }
  }

  if (missingKeys.length > 0) {
    throw new Error(`Missing params: ${missingKeys.join(', ')}`);
  }
}
