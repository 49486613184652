import { formatToYearMonthDay } from '@kk/shared/utils/date';
import { assertParamsPrescence } from '@kk/shared/utils/validators';
import { useParams, useRouteLoaderData } from 'react-router';
import { RequestOpts } from '@/api/api-types';
import { assertNoLoaderErrors } from '@/api/errors';
import { fetchCalculationHolidaysQuery } from '@/api/hooks/loan/useCalculationHolidays';
import { fetchCreateStandaloneCalculationpath } from '@/api/hooks/loan/useCreateStandaloneCalculationpath';

export type LoaderData = Awaited<ReturnType<typeof loader>>;

export type Params = {};

export const id = 'LeasingPage' as const;

async function loader({
  params,
  opts,
}: {
  params: Params;
  opts?: RequestOpts;
}) {
  assertParamsPrescence(params);

  const calculationPath = await fetchCreateStandaloneCalculationpath({}, opts);
  assertNoLoaderErrors(id, [calculationPath]);
  const startDate = new Date();
  startDate.setDate(startDate.getDate() + 1);
  // endDate should be april 30 the following year
  const endDate = new Date(startDate.getFullYear() + 1, 3, 30);

  const holidayData = await fetchCalculationHolidaysQuery(
    {
      calculationPath,
      startDate: formatToYearMonthDay(startDate),
      endDate: formatToYearMonthDay(endDate),
    },
    opts,
  );
  assertNoLoaderErrors(id, [holidayData]);

  return {
    calculationPath,
    holidayData,
  };
}

export const useLeasingPageLoaderData = () => useRouteLoaderData(id);

export const useLeasingPageParams = () => useParams<Params>();

export default loader;
