import { handleSettledPromises } from '@kk/shared/utils/async';
import { assertParamsPrescence } from '@kk/shared/utils/validators';
import { useParams, useRouteLoaderData } from 'react-router';
import { RequestOpts } from '@/api/api-types';
import { assertNoLoaderErrors } from '@/api/errors';
import { fetchCaseQuery } from '@/api/hooks/case/useCase';
import { fetchCaseAssigneesQuery } from '@/api/hooks/case/useCaseAssignees';
import { fetchDocumentByRecordIdQuery } from '@/api/hooks/case/useDocumentByRecordId';
import { fetchCompanyQuery } from '@/api/hooks/company/useCompany';
import { fetchLoanQuery } from '@/api/hooks/loan/useLoan';

export type LoaderData = Awaited<ReturnType<typeof loader>>;

export type Params = {
  companyId?: string;
  contractId?: string;
  caseId?: string;
  recordId?: string;
  recordName?: string;
  casePhaseCode?: string;
};

export const id = 'caseRecordPage' as const;

async function loader({
  params,
  opts,
}: {
  params: Params;
  opts?: RequestOpts;
}) {
  assertParamsPrescence(params);
  const { companyId, contractId, caseId, recordId } = params;
  const data = await handleSettledPromises([
    fetchCompanyQuery({ companyId }, opts),
    fetchLoanQuery({ contractId }, opts),
    fetchCaseQuery({ caseId }, opts),
    fetchDocumentByRecordIdQuery({ recordId }, opts),
    fetchCaseAssigneesQuery({ caseId }, opts),
  ]);
  assertNoLoaderErrors(id, data);
  const [company, loanInfo, loanCase, caseRecord, caseAssignees] = data;
  return {
    company,
    loanInfo,
    loanCase,
    caseRecord,
    caseAssignees,
  };
}

export const useCaseRecordPageLoaderData = () => useRouteLoaderData(id);

export const useCaseRecordPageParams = () => useParams<Params>();

export default loader;
