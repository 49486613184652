import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import LogoLight from '@/assets/logo-darkmode.svg?react';

export function Logo({ className }: { className?: string }) {
  const { t } = useTranslation();

  const title = t('app.title');

  return (
    <figure className="text-white">
      <Link
        to="/"
        className="flex items-center justify-center rounded px-2 py-1"
      >
        <LogoLight data-testid="logo" className={clsx('size-8', className)} />
        <figcaption className="ml-3 inline-block">{title}</figcaption>
      </Link>
    </figure>
  );
}
