import { useMemo } from 'react';
import {
  AccountInfo,
  InteractionStatus,
  SilentRequest,
} from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { unique } from '@kk/shared/utils/iterables';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import ms from 'ms';
import { useSnapshot } from 'valtio';
import { useSettings } from '@/api/hooks/useSettings';
import state from '@/state';
import { caseApi } from '..';
import { handleTokenError } from '../errors';
import { DecodedToken, parseJwt } from '../options';
import { isPhaseInProgressOrCompleted } from '../predicates/casePhase';
import { isCaseClosed } from '../predicates/loanCase';
import { loginRequest } from './auth.config';
import {
  allPermissions,
  CASE_SCOPES,
  PERMISSIONS,
  Role,
  roles,
  type Department,
  type Scope,
} from './permissions';

export function checkAccess(claims?: DecodedToken | null): boolean {
  return claims?.roles.some((role: Role) => roles.includes(role)) ?? false;
}

/**
 * Retrieves the currently active user account from the MSAL instance.
 * @returns {Account | null} - The currently active user account, or null if no user is signed in.
 */
export function useActiveAccount(): AccountInfo | null {
  const { instance } = useMsal();
  return instance.getActiveAccount() ?? null;
}

/**
 * Custom React hook that retrieves and decodes the access token from MSAL's silent token acquisition flow.
 * @returns {DecodedToken | null} The decoded access token.
 */
export function useDecodedAccessToken(): DecodedToken | undefined | null {
  const { instance } = useMsal();
  const activeAccount = useActiveAccount();

  const { data: accessToken } = useQuery<DecodedToken | undefined>({
    queryKey: ['decode-access-token', activeAccount?.username],
    queryFn: async () => {
      if (!activeAccount) throw new Error('No active account');
      const accessTokenRequest: SilentRequest = {
        scopes: loginRequest.scopes,
        account: activeAccount,
      };
      try {
        const tokenResponse =
          await instance.acquireTokenSilent(accessTokenRequest);
        const decodedToken = parseJwt(tokenResponse.accessToken);
        return decodedToken;
      } catch (error) {
        handleTokenError(error);
      }
    },
    enabled: !!activeAccount,
    placeholderData: keepPreviousData,
    staleTime: ms('40m'),
  });

  return accessToken ?? null;
}

/**
 * Custom React hook that returns a boolean indicating whether the user is currently being authenticated.
 * @returns {boolean} True if the authentication process is in progress or if the access token is null, false otherwise.
 */
export function useIsAuthenticating(): boolean {
  const { inProgress } = useMsal();
  const accessToken = useDecodedAccessToken();

  return (
    [
      InteractionStatus.Startup,
      InteractionStatus.HandleRedirect,
      InteractionStatus.Login,
    ].includes(inProgress) || accessToken === null
  );
}

/**
 * Retrieves the roles assigned to the currently active user account.
 * @returns {string[]} - An array of role names assigned to the user account, or an empty array if no roles are assigned.
 */
export function useAccountRoles(): string[] {
  const accessToken = useDecodedAccessToken();
  // allow devtools to override the roles for testing purposes
  const { devtoolsEnabled, testingRole } = useSnapshot(state);
  if (process.env.NODE_ENV !== 'test') {
    if (devtoolsEnabled && testingRole) {
      return [testingRole];
    }
  }
  return accessToken?.roles || [];
}

/**
 * Checks if the currently authenticated user has access to the application.
 * @returns {boolean} - Whether or not the user has access to the application.
 */
export function useHasAccess(): boolean {
  const isAuthenticated = useIsAuthenticated();
  const isAuthenticating = useIsAuthenticating();
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const accessToken = useDecodedAccessToken();

  const hasAccess =
    isAuthenticated && activeAccount && !isAuthenticating
      ? checkAccess(
          accessToken ?? (activeAccount.idTokenClaims as DecodedToken),
        )
      : false;

  return hasAccess;
}

/**
 * Retrieves the unique permissions for the current user's roles.
 * @returns - An object containing the unique permissions for the user's roles.
 */
export function usePermissions(): Partial<typeof allPermissions> {
  const roles = useAccountRoles();
  return useMemo(
    () =>
      unique(
        roles
          .flatMap((role) => {
            const permissions = PERMISSIONS[role];
            if (permissions) {
              return permissions;
            }
            return null;
          })
          .filter((permission) => permission !== null),
      ),
    [roles],
  );
}

/**
 * Checks if a given scope is included in the user's permissions.
 * @param {string} scope - The scope to check for in the permissions.
 * @returns {boolean} - Whether or not the scope is included in the permissions.
 */
export function useHasPermission(scope: Partial<Scope>): boolean {
  const permissions = usePermissions();
  return permissions.includes(scope) || false;
}

/**
 * A custom hook that returns an array of current departments based on user roles and department role mapping from settings.
 *
 * @returns {Array.<string>} An array of current departments
 */
export function useCurrentDepartments(): Array<Department> {
  const { data: settings } = useSettings();
  const { departmentRoleMap } = settings ?? {};

  const roles = useAccountRoles();

  const currentDepartments = useMemo<Array<Department> | []>(() => {
    if (roles.length === 0 || !departmentRoleMap) return [];
    return unique(
      roles.flatMap((role) => departmentRoleMap[role]).filter(Boolean),
    ) as Array<Department>;
  }, [roles, departmentRoleMap]);

  return currentDepartments;
}

/**
 * A custom React hook that checks if the specified department is in the current departments list.
 *
 * @param {Department} department - The department to check
 * @returns {boolean} `true` if the department is in the current departments list, `false` otherwise
 */
export function useIsInDepartment(department: Department): boolean {
  const currentDepartments = useCurrentDepartments();
  return currentDepartments.includes(department);
}

/**
 * Determines if the current user has permission to close a case based on specific conditions.
 * Checks if the user has the permission to close a case, if the execution has not started, and if the case is not closed.
 *
 * @param {caseApi.CaseModel} loanCase - The case to check for user's closing rights.
 * @returns {boolean} True if the user can close the case under the specified conditions, false otherwise.
 */
export function useCanUserCloseCase(loanCase?: caseApi.CaseModel): boolean {
  const hasCloseCasePermission = useHasPermission(CASE_SCOPES.closeCase);
  const executionStarted = isPhaseInProgressOrCompleted(loanCase);
  const caseClosed = isCaseClosed(loanCase);

  return Boolean(
    hasCloseCasePermission &&
      executionStarted === false &&
      caseClosed === false,
  );
}

/**
 * Checks if the current user is allowed to take over a specific loan case considering various criteria.
 * Verifies if the user has the permission to take over the case, if the case is not closed,
 * and if the case is not already assigned to the user.
 *
 * @param {caseApi.CaseModel} loanCase - The loan case to check for take-over eligibility.
 * @returns {boolean} True if the user meets the requirements to take over the case, false otherwise.
 */
export function useCanUserTakeOverCase(loanCase?: caseApi.CaseModel): boolean {
  const { OnPremSamAccountName } = useDecodedAccessToken() ?? {};
  const hasTakeOverCasePermission = useHasPermission(CASE_SCOPES.takeOverCase);
  const caseClosed = isCaseClosed(loanCase);

  // check if the case is already assigned to the user
  const isAlreadyAssignedToCurrentUser =
    loanCase?.caseHandlerCode?.toLowerCase() ===
    OnPremSamAccountName?.toLowerCase();

  return Boolean(
    hasTakeOverCasePermission &&
      caseClosed === false &&
      isAlreadyAssignedToCurrentUser === false,
  );
}
