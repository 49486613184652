import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { SafeTo } from '@/routes/types';
import { generateUrl } from '@/routes/utils/generateUrl';

const ActiveIndicator = () => (
  <div
    className={clsx(
      'bg-blue-20 absolute bottom-0 right-1/2 mx-auto block h-[3px] w-[calc(100%-6px)]',
      'flex-none translate-x-1/2 rounded-t-[1px]',
    )}
  />
);

function TopNavigationLink({
  children,
  to,
}: {
  children: React.ReactNode;
  to: SafeTo;
}) {
  return (
    <NavLink
      className={clsx(
        'active:text-blue-10 hover:bg-blue-100 focus-visible:bg-blue-100',
        'group relative flex h-full items-center px-1.5 py-2',
      )}
      to={to}
    >
      {({ isActive }) => (
        <>
          <span>{children}</span>
          {isActive ? <ActiveIndicator /> : null}
        </>
      )}
    </NavLink>
  );
}

export function TopNavigation() {
  const { t } = useTranslation();

  return (
    <div className="text-neutral-0 flex h-full items-center space-x-2">
      <TopNavigationLink to={generateUrl('/', {})}>
        {t('dashboard')}
      </TopNavigationLink>
      <TopNavigationLink to={generateUrl('/beregner', {})}>
        {t('standaloneCalculator')}
      </TopNavigationLink>
    </div>
  );
}

export default TopNavigation;
